import { Flex, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import RaisedButton from "../components/RaisedButton";
import QuitIcon from "../components/icons/QuitIcon";
import RaisedCard from "../components/RaisedCard";
import { signOut } from "aws-amplify/auth";
// import DeleteIcon from "../components/icons/DeleteIcon";
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import { useQuery} from "@tanstack/react-query";
// import { createDeleteAccountRequest } from "../features/user/services/createDeleteAccountRequest";
import useModal from "../hooks/useModal";
// import { listDeleteAccountRequest } from "../features/user/services/listDeleteAccountRequest";

export interface IButtonCardProps {}

export default function ButtonCard(props: IButtonCardProps) {
  const {} = props;

  // const queryClient = useQueryClient();

  // const { mutateAsync: deleteAccount_mutateAsync, isLoading } = useMutation({
  //   mutationKey: ["create delete account request"],
  //   mutationFn: async () => await createDeleteAccountRequest(),
  // });

  // const { data: requests } = useQuery({
  //   queryKey: ["delete-account-request", "list"],
  //   queryFn: async () => {
  //     return await listDeleteAccountRequest();
  //   },
  // });

  // const hasActiveRequest = React.useMemo(() => {
  //   return (
  //     requests?.some((request) => {
  //       const isExpired = new Date(request.expiry) < new Date();
  //       return !isExpired;
  //     }) ?? false
  //   );
  // }, [requests]);

  const [CheckEmailModal, showCheckEmail] = useModal(
    {
      title: "Check your email",
      hasExitButton: true,
      ReactComponent: () => (
        <Flex direction={"column"} alignItems={"center"}>
          <Text>
            We have sent you an email with a link to confirm your account
            deletion. Please check your email for further instructions.
          </Text>
          <RaisedButton onClick={() => showCheckEmail(false)}>
            Okay
          </RaisedButton>
        </Flex>
      ),
    },
    []
  );
  // const [AlreadyRequestedModal, showAlreadyRequested] = useModal(
  //   {
  //     title: "Check your email",
  //     hasExitButton: true,
  //     ReactComponent: () => (
  //       <Flex direction={"column"} alignItems={"center"}>
  //         <Text>
  //           It seems that you've already requested to delete your account.
  //           Please check your email for further instructions.
  //         </Text>
  //         <RaisedButton onClick={() => showCheckEmail(false)}>
  //           Okay
  //         </RaisedButton>
  //       </Flex>
  //     ),
  //   },
  //   []
  // );

  return (
    <>
      {/* <AlreadyRequestedModal /> */}
      <CheckEmailModal />
      <RaisedCard>
        {/* <Heading id="sectionTitle">
      Account Information
      </Heading> */}
        <Flex>
          <RaisedButton
            variation="destructive"
            gap={"xs"}
            fontSize={"smaller"}
            size="small"
            onClick={async () => {
              try {
                await signOut();
              } catch (error) {}
            }}
          >
            <QuitIcon />
            Sign Out
          </RaisedButton>
          {/* <RaisedButton
            variation="destructive"
            backgroundColor={"black"}
            gap={"xs"}
            fontSize={"smaller"}
            size="small"
            isLoading={isLoading}
            onClick={async () => {
              if (hasActiveRequest) {
                showAlreadyRequested(true);
              } else {
                await deleteAccount_mutateAsync();

                showCheckEmail(true);
                queryClient.invalidateQueries([
                  "delete-account-request",
                  "list",
                ]);
              }
            }}
          >
            <DeleteIcon />
            Delete Account
          </RaisedButton> */}
        </Flex>
      </RaisedCard>
    </>
  );
}
