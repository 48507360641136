import React, { useMemo } from "react";
import { RaisedButton } from "../../../components";
import deleteAccount from "../services/deleteAccount";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getDeleteAccountRequest } from "../services/getDeleteAccountRequest";
import { Flex, Text } from "@aws-amplify/ui-react";
import BasicLayout from "../../../layouts/BasicLayout";
import RaisedCard from "../../../components/RaisedCard";
import { FaExclamationCircle, FaExclamationTriangle } from "react-icons/fa";

export default function DeleteAccountConfirmationPage() {
  const { id } = useParams();

  const { mutateAsync: deleteAccount_mutateAsync, isLoading } = useMutation({
    mutationKey: ["deleteAccount"],
    mutationFn: async () => await deleteAccount(),
  });

  const { data: request } = useQuery({
    queryKey: ["delete-account-request", id],
    queryFn: async () => await getDeleteAccountRequest(id),
    retry: false,
    enabled: !!id,
    suspense: true,
  });

  const isExpired = useMemo(() => {
    if (!request) return false;
    const expirationDate = new Date(request.expiry);
    return expirationDate < new Date();
  }, [request]);

  return (
    <BasicLayout>
      <Flex
        padding={"medium"}
        direction={"column"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <RaisedCard>
          <Flex direction={"column"} alignItems={"center"}>
            {!isExpired ? (
              <>
                <FaExclamationTriangle size={120} color={"#DE3721"} />
                <Text fontSize={"larger"} textAlign={"center"}>
                  Clicking below will initate the deletion of your account.
                </Text>
                <Text fontSize={"larger"} textAlign={"center"}>
                  All of your data will be removed from our records.
                </Text>
                <Text fontSize={"larger"} textAlign={"center"}>
                  This action is irreversable.
                </Text>
                <RaisedButton
                  variation="destructive"
                  isLoading={isLoading}
                  loadingText="Deleting Account..."
                  onClick={async () => {
                    await deleteAccount_mutateAsync();
                  }}
                >
                  Delete Account
                </RaisedButton>
              </>
            ) : (
              <>
                <FaExclamationCircle size={120} color={"grey"} />
                <Text fontSize={"larger"} textAlign={"center"}>
                  This deletion request has expired.
                </Text>
              </>
            )}
          </Flex>
        </RaisedCard>
      </Flex>
    </BasicLayout>
  );
}
