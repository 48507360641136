import React from "react";
import { Flex, ResponsiveStyle, StyleToken, Text } from "@aws-amplify/ui-react";
import { uploadProtectedImage } from "../utils/uploadProtectedImage";
import { S3ObjectProtected } from "../../../API";
import { FontSizeKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import { Property } from "csstype";
import { ImageDimensions } from "./ImageGallery";
import { FaUpload } from "react-icons/fa";

export default function ImageUploader({
  htmlFor,
  onUpload,
  fontSize,
  children,
}: {
  htmlFor: string;
  onUpload: (
    image: S3ObjectProtected,
    url: string,
    dimensions: ImageDimensions
  ) => void;
  fontSize?:
    | ResponsiveStyle<
        FontSizeKeys<StyleToken<Property.FontSize<0 | (string & {})>>>
      >
    | undefined;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <Flex margin={"0"} padding={"0"}>
      <label htmlFor={htmlFor} className="amplify-button--link">
        <Text
          height={"36px"}
          paddingTop={"xxxs"}
          paddingBottom={"small"}
          id="raised-btn"
          borderRadius={"small"}
          backgroundColor={"white"}
          color={"black"}
          fontSize={fontSize ?? "medium"}
          style={{ cursor: "pointer" }}
        >
          {children}
          {"    "}
          <FaUpload />
        </Text>
      </label>
      <input
        hidden={true}
        id={htmlFor}
        type="file"
        accept="image/*"
        onChange={async (e) => {
          if (!e.target.files) return;

          const file = e.target.files[0];
          let width = 0;
          let height = 0;
          if (file) {
            const img = new Image();
            img.onload = function () {
              width = img.width;
              height = img.height;
              console.log("Width:", width, "Height:", height);
            };
            const reader = new FileReader();
            reader.onload = function (e) {
              img.src = e.target?.result as string; // Set the image source to the file data
            };
            reader.readAsDataURL(file); // Read the file as a data URL
          }
          const result = await uploadProtectedImage(file);
          if (result) {
            const { image, url } = result;

            onUpload(image as S3ObjectProtected, url.toString(), {
              width,
              height,
            });
          }
        }}
      />
    </Flex>
  );
}
