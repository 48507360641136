import { useQuery, useQueryClient } from "@tanstack/react-query";
import getUser from "../util/User/getUser";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useMemo } from "react";
import posthog from "posthog-js";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { updateUser } from "../util/User/updateUser";
import getUserOrg from "../features/user/services/getUserOrg";
// import { fetchUserAttributes } from "aws-amplify/auth";

export default function useUserQuery() {
  const { user: authUser } = useAuthenticator((context) => [context.user]);

  const queryClient = useQueryClient();

  useQuery(["auth-user", authUser?.username], () => authUser, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    enabled: authUser !== undefined,
  });

  useQuery(
    ["user-attributes", authUser?.username],
    async () => {
      try {
        const attributes = await fetchUserAttributes();
        if (attributes) {
          posthog.identify(attributes.email, {
            sub: attributes.sub,
          });
          return attributes;
        } else return null;
      } catch (err) {
        return null;
      }
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: 0,
      enabled: authUser !== undefined,
    }
  );

  const authenticated = useMemo(() => {
    return !!authUser;
  }, [authUser]);

  const userQuery = useQuery(
    ["user", authUser?.username],
    async () => {
      const user = await getUser();
      if (Object.keys(user).length === 0) throw new Error("User not found");
      if (user.customerTier !== "Premium" && user.modelType === "smarter") {
        await updateUser(user.creatorId, { modelType: "faster" });
        queryClient.invalidateQueries(["user", authUser?.username]);
      }

      return user;
    },
    {
      retry: false,
      enabled: authenticated === true && authUser !== undefined,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: 0,
    }
  );
  const { data: org } = useQuery(
    ["user", "org", userQuery.data?.orgId],
    async () => {
      const org = await getUserOrg();

      return org;
    },
    {
      retry: false,
      enabled: userQuery.data?.orgId !== undefined,
    }
  );

  const gibblyUser = useMemo(() => userQuery.data, [userQuery.data]);

  const tokenBalance = useMemo(() => {
    if (!gibblyUser) return 0;
    return gibblyUser.tokensBase + gibblyUser.tokensBonus;
  }, [gibblyUser]);

  const tokenBalanceString = useMemo(() => {
    if (gibblyUser?.customerTier === "Premium") return "Unlimited 👑";
    return tokenBalance.toLocaleString();
  }, [tokenBalance]);

  return {
    user: gibblyUser,
    authenticated,
    tokenBalance,
    tokenBalanceString,
    org,
    ...userQuery,
  };
}
