import React from "react";
import { Image, ImageProps } from "@aws-amplify/ui-react";

import goldMedal from "../assets/images/game/gold-medal.png";
import silverMedal from "../assets/images/game/silver-medal.png";
import bronzeMedal from "../assets/images/game/bronze-medal.png";

type MedalProps = Omit<ImageProps, "alt" | "src"> & {
  type: "gold" | "silver" | "bronze";
};

export default function Medal({ type, ...imageProps }: MedalProps) {
  return (
    <Image
      alt={type + " medal"}
      src={
        type === "gold"
          ? goldMedal
          : type === "silver"
          ? silverMedal
          : bronzeMedal
      }
      {...imageProps}
    />
  );
}
