import React, { useState } from "react";
import { Tabs, Flex, View } from "@aws-amplify/ui-react";

import PixabayGallery from "./PixabayGallery";
import PexelsGallery from "./PexelsGallery";
import { S3ObjectProtected } from "../../../API";
import ImageUploader from "./ImageUploader";
import RaisedButton from "../../../components/RaisedButton";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import AutoPickedImageCollection from "../../generate/components/AutoPickedImageCollection";
import { FaArrowLeft } from "react-icons/fa";
//import UnsplashGallery from "./UnsplashGallery";

export type ImageDimensions = {
  width: number;
  height: number;
};

export default function ImageGallery({
  onUpload,
  text = "",
}: {
  text?: string;
  onUpload: (
    image: S3ObjectProtected,
    url: string,
    dimensions: ImageDimensions
  ) => void | Promise<void>;
}): JSX.Element {
  const [inputSubmitted, setInputSubmitted] = useState(text ?? "");
  const [imageSearchInput, setImageSearchInput] = useState(text ?? "");

  const [autoPick, setAutoPick] = useState(false);

  return (
    <View>
      <Flex direction={"column"} gap={"xxxs"}>
        <Flex justifyContent={"space-between"}>
          {autoPick ? (
            <RaisedButton onClick={() => setAutoPick(false)}>
              <FaArrowLeft />
            </RaisedButton>
          ) : (
            <RaisedButton
              backgroundColor={"#9100ff"}
              onClick={() => setAutoPick(true)}
            >
              <SparkleIcon />
            </RaisedButton>
          )}
          <ImageUploader htmlFor={"device-image-uploader"} onUpload={onUpload}>
            Upload{" "}
          </ImageUploader>
        </Flex>
        {autoPick ? (
          <AutoPickedImageCollection text={text} onUpload={onUpload} />
        ) : (
          <Tabs.Container spacing="equal" defaultValue="pexels">
            <Tabs.List>
              <Tabs.Item value="pexels">Pexels</Tabs.Item>
              <Tabs.Item value="pixabay">Pixabay</Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value="pixabay">
              <PixabayGallery
                inputSubmitted={inputSubmitted}
                setInputSubmitted={setInputSubmitted}
                imageSearchInput={imageSearchInput}
                setImageSearchInput={setImageSearchInput}
                onUpload={onUpload}
              />
            </Tabs.Panel>
            <Tabs.Panel value="pexels">
              <PexelsGallery
                inputSubmitted={inputSubmitted}
                setInputSubmitted={setInputSubmitted}
                imageSearchInput={imageSearchInput}
                setImageSearchInput={setImageSearchInput}
                onUpload={onUpload}
              />
            </Tabs.Panel>
          </Tabs.Container>
        )}
      </Flex>
    </View>
  );
}
