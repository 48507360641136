import React, { useEffect } from "react";
import {
  Button,
  Flex,
  Image,
  View,
  Text,
  Grid,
  Heading,
  Card,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { Carousel, Container } from "react-bootstrap";
import logo from "/logoWide.png";
import { PageDescription, PageIndex, PageTitle } from "../util/SEO";
import SparkleIcon from "../components/icons/SparkleIcon";
import Footer from "../layouts/Footer";
import PlayIcon from "../components/icons/PlayIcon";
import { FaBell, FaPlay, FaSignInAlt } from "react-icons/fa";
import useEffectOnce from "../hooks/useEffectOnce";
import { CookieConsentForm } from "./CookieConsentForm";
import RaisedButton from "../components/RaisedButton";
import { useUserContext } from "../context/UserContextProvider";
import ArrowRightIcon from "../components/icons/ArrowRightIcon";
import TestimonalItem from "../layouts/TestimonalItem";
import HomePageCard from "../layouts/HomePageCard";

import teacherComputer from "../assets/images/home-page/TeacherComputer.jpg";
import teacherStudents from "../assets/images/home-page/TeacherStudents.jpg";
import testimonialAvatar1 from "../assets/images/home-page/Teacher1.png";
import testimonialAvatar2 from "../assets/images/home-page/Teacher2.png";
import testimonialAvatar3 from "../assets/images/home-page/Teacher3.png";
import testimonialAvatar4 from "../assets/images/home-page/Teacher4.png";
import testimonialAvatar5 from "../assets/images/home-page/Teacher5.png";
import testimonialAvatar6 from "../assets/images/home-page/Teacher6.png";
import testimonialAvatar9 from "../assets/images/home-page/Teacher9.png";
import testimonialAvatar16 from "../assets/images/home-page/Teacher16.png";
import testimonialAvatar18 from "../assets/images/home-page/Teacher18.png";

import magicWandIcon from "../assets/images/icons/magic-wand.png";
import magicIcon from "../assets/images/icons/magic.png";
import documentIcon from "../assets/images/icons/document.png";
import appleIcon from "../assets/images/icons/apple.png";
import graphIcon from "../assets/images/icons/graph.png";
import groupIcon from "../assets/images/icons/group.png";
import newsletterIcon from "../assets/images/icons/newsletter.png";

export default function Home() {
  PageTitle("Gibbly | AI Lesson and Quiz Generators | Made For Teachers");
  PageDescription(
    "Generate ready to use and curriculum aligned gamified quizzes, assessments, lessons, and lesson plans in minutes with AI. Made for teachers. Save hours of prep time with Gibbly! "
  );
  PageIndex("");

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, []);

  useEffectOnce(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("show", entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(entry.target);
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  });

  const { authenticated } = useUserContext();

  const navigate = useNavigate();

  const testimonials: {
    testimonial: string;
    name: string;
    src: string;
    isLazy?: boolean;
  }[] = [
    {
      testimonial:
        "Gibbly has been an absolute pleasure to use! It has made lesson planning so fun and easy! It has saved me so much time while creating relevant curriculum based lessons!",
      name: "Melyssa - Teacher",
      src: testimonialAvatar1,
    },
    {
      testimonial:
        "Gibbly helps take the game off the computer and into the minds of the students. Using AI to create a quiz that ties into the content and then made into a lesson sets Gibbly apart.",
      name: "Matthew - Teacher",
      src: testimonialAvatar2,
      isLazy: true,
    },
    {
      testimonial:
        "Gibbly is excellent for peer induced teamwork! My students love and I love having the option to have teams. Great for review or study guides.",
      name: " Candy - Teacher",
      src: testimonialAvatar3,
      isLazy: true,
    },
    {
      testimonial:
        "Gibbly is a teacher's dream. It cuts down on the amount of time lesson creation takes and leaves more time for other aspects of the job.",
      name: "Danielle - Teacher",
      src: testimonialAvatar4,
      isLazy: true,
    },
    {
      testimonial:
        "Gibbly saves so much time in creating quick, fact-based assessments that I can use as formative or summative assessments.",
      name: "Alie - Teacher",
      src: testimonialAvatar5,
      isLazy: true,
    },
    {
      testimonial:
        "For all the teachers, educators, and homeschooling parents, I highly recommend Gibbly. Try it! You will not be disappointed!",
      name: "Tina - Homeschooling Parent",
      src: testimonialAvatar6,
      isLazy: true,
    },
    {
      testimonial:
        "I like that in team mode it requires students to be mindful of their turn when questions come up. I also like how quick and simple things are.",
      name: "George - Teacher",
      src: testimonialAvatar9,
      isLazy: true,
    },
    {
      testimonial:
        "Gibbly is a timesaver for every teacher. It can reduce the workload off of teachers immensely. I highly recommended for schools, online teaching and training sessions.",
      name: "Shahinaz - Teacher",
      src: testimonialAvatar16,
      isLazy: true,
    },
    {
      testimonial:
        'I love the quiz-making feature. I teach adults 1:1 and use the quizzes as stand-alone activities. I also enjoy the space provided for adding qualifying information to the "Correct answer".',
      name: "Ralph - Teacher",
      src: testimonialAvatar18,
      isLazy: true,
    },
  ];

  return (
    <>
      {CookieConsentForm()}
      <View>
        <Flex
          position="sticky"
          top={"0"}
          gap={"xs"}
          backgroundColor={"white"}
          id="highestelement"
          padding={"small"}
          boxShadow={"0px -2px rgb(0 0 0 / 10%) inset"}
          justifyContent={{ base: "center", small: "space-between" }}
          wrap="wrap"
        >
          <Flex height={"100%"} alignItems={"center"}>
            <Image
              loading="eager"
              objectFit={"cover"}
              height={"55px"}
              width={"165px"}
              src={logo}
              alt="Gibbly Logo, AI Tools for Teachers"
            />
          </Flex>

          {/* <Media queries={{ small: { maxWidth: 480 } }}>
            {(matches) =>
              matches.small ? (
                <>
                  <Container>
                    <Divider size={"small"} />
                  </Container>
                </>
              ) : (
                <></>
              )
            }
          </Media> */}

          <Flex
            padding={"xxxs"}
            alignItems={"center"}
            justifyContent={"center"}
            wrap="wrap"
          >
            <Button
              variation="menu"
              fontSize={{ base: "small", medium: "medium" }}
              onClick={() => navigate("/upgrade/")}
              id="raised-btn"
            >
              Pricing
            </Button>

            {authenticated ? (
              <>
                <RaisedButton
                  variation="menu"
                  fontSize={{ base: "small", medium: "medium" }}
                  onClick={() => navigate("/play/join/")}
                  gap={"xxs"}
                >
                  Join
                  <PlayIcon />
                </RaisedButton>

                <RaisedButton
                  variation="primary"
                  fontSize={{ base: "small", medium: "medium" }}
                  onClick={() => navigate("/dashboard/library/")}
                  gap={"xs"}
                >
                  Dashboard
                  <ArrowRightIcon />
                </RaisedButton>
              </>
            ) : (
              <>
                {
                  <>
                    <RaisedButton
                      variation="primary"
                      fontSize={{ base: "small", medium: "medium" }}
                      onClick={() => navigate("/auth/signup")}
                      gap={"small"}
                    >
                      Get Started
                    </RaisedButton>
                    <RaisedButton
                      variation="menu"
                      fontSize={{ base: "small", medium: "medium" }}
                      onClick={() => navigate("/auth/login")}
                      gap={"xs"}
                    >
                      Log In
                      <FaSignInAlt />
                    </RaisedButton>

                    <RaisedButton
                      variation="link"
                      fontSize={{ base: "small", medium: "medium" }}
                      onClick={() => navigate("/play/join/")}
                      gap={"xxs"}
                    >
                      Play
                      <PlayIcon />
                    </RaisedButton>
                  </>
                }
              </>
            )}
          </Flex>
        </Flex>

        <View
          as="section"
          className="hidden"
          padding={{ base: "small", large: "medium" }}
          paddingTop={{ base: "xs", large: "xl" }}
        >
          <Container>
            <Grid
              templateColumns={{ base: "1fr", large: "60% 40%" }}
              gap={"medium"}
              textAlign={"left"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={{ base: "0", large: "small" }}
              paddingTop={{ base: "xxxs", large: "large" }}
              paddingBottom="xl"
            >
              <Flex
                textAlign={"left"}
                direction={"column"}
                paddingTop={{ base: "xl", large: "large" }}
                paddingBottom="medium"
                gap="zero"
              >
                <Heading
                  id={"displayText"}
                  level={1}
                  fontSize={{ base: "xl", large: "xxl", xl: "xxxxl" }}
                  fontWeight={"800"}
                  padding={"medium"}
                  paddingBottom="small"
                  marginBottom={"0"}
                >
                  Generate Lessons & Quizzes with AI
                </Heading>

                <Text
                  paddingLeft={"large"}
                  paddingRight={"xl"}
                  paddingTop={"small"}
                  paddingBottom={{ base: "xl", large: "xxxs" }}
                  fontSize={{ base: "xs", medium: "medium" }}
                >
                  Save hours of prep each week with Gibbly! Generate ready to
                  use lessons, lesson plans, assessments, quizzes, and gamified
                  review assignments in minutes on any topic with AI.
                </Text>

                <Flex
                  padding={{ base: "small", medium: "large" }}
                  paddingTop={"large"}
                  justifyContent={{ base: "center", large: "left" }}
                >
                  {authenticated ? (
                    <>
                      <Button
                        id={"raised-btn"}
                        textAlign={"center"}
                        gap="small"
                        size="small"
                        fontSize={{ base: "xs", medium: "medium" }}
                        backgroundColor={"orange.60"}
                        variation="primary"
                        onClick={() => navigate("/dashboard/library")}
                      >
                        <SparkleIcon />
                        Try Today
                      </Button>
                    </>
                  ) : (
                    <>
                      {
                        <>
                          <Button
                            id={"raised-btn"}
                            gap="small"
                            textAlign={"center"}
                            size="small"
                            backgroundColor={"orange.60"}
                            fontSize={{ base: "xs", medium: "medium" }}
                            variation="primary"
                            onClick={() => navigate("/auth/signup")}
                          >
                            <SparkleIcon />
                            Sign Up Free
                          </Button>
                        </>
                      }
                    </>
                  )}

                  <a
                    href="https://www.youtube.com/watch?v=hChoaaIc6No"
                    target="_blank"
                  >
                    <Button
                      id={"raised-btn"}
                      gap="small"
                      textAlign={"center"}
                      fontSize={{ base: "xs", medium: "medium" }}
                      color="neutral.90"
                      size="small"
                      backgroundColor={"white"}
                      variation="link"
                    >
                      <FaPlay />
                      Watch Video
                    </Button>
                  </a>
                </Flex>
              </Flex>

              <Flex justifyContent={"center"}>
                <Image
                  alt="A teacher smiling sitting at a computer"
                  boxShadow={"medium"}
                  borderRadius={"80px xl"}
                  width={{
                    base: "250px",
                    medium: "350px",
                    large: "500px",
                    xl: "600px",
                  }}
                  src={teacherComputer}
                ></Image>
              </Flex>
            </Grid>
          </Container>
        </View>

        <View
          as="section"
          paddingTop="small"
          paddingBottom="small"
          className="hidden"
          backgroundColor={"purple.60"}
          style={{
            backgroundImage:
              "linear-gradient(to top left, var(--amplify-colors-teal-100) ,var(--amplify-colors-teal-60))",
          }}
        >
          <Carousel pause="hover" indicators={false}>
            {testimonials.map((t, i) => (
              <Carousel.Item key={i}>
                <TestimonalItem
                  testimonial={t.testimonial}
                  name={t.name}
                  src={t.src}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </View>

        <Flex direction={"column"} rowGap={"small"}>
          <View
            as="section"
            padding={{ base: "small", large: "large" }}
            paddingTop="7vh"
            paddingBottom="9vh"
            className="hidden"
            backgroundColor="neutral.10"
          >
            <Container>
              <Flex
                direction={{ base: "column-reverse", large: "row" }}
                alignItems={{ base: "center", large: "center" }}
                columnGap={"xxl"}
              >
                <View height={"100%"} minHeight="100px" padding={0}>
                  <Image
                    src={teacherStudents}
                    loading="lazy"
                    borderRadius={"xl 80px"}
                    alt="A female teacher talking with her two female students at a computer"
                    width={{
                      base: "250px",
                      medium: "350px",
                      large: "500px",
                      xl: "600px",
                    }}
                  />
                </View>

                <View width={{ base: "100%", large: "65%" }}>
                  <Heading
                    id={"displayText"}
                    fontWeight={"bold"}
                    level={3}
                    lineHeight={"1"}
                    fontSize={{ base: "large", large: "xl", xl: "xxl" }}
                    paddingBottom={"small"}
                    textAlign={{ base: "center", large: "left" }}
                  >
                    Save Time on Lesson Prep
                  </Heading>
                  <Text
                    paddingBottom={"large"}
                    textAlign={"left"}
                    color={"neutral.90"}
                    fontSize={{ base: "small", medium: "medium" }}
                  >
                    Create lessons, gamified quizzes, assessments, and review
                    assignments that your students love and that save you hours
                    of prep every week.
                  </Text>

                  <Grid
                    templateColumns={{ base: "1fr", small: "1fr 1fr" }}
                    gap={"large"}
                  >
                    <HomePageCard
                      icon={
                        <Image
                          src={magicWandIcon}
                          alt="A magic wand"
                          loading="lazy"
                        />
                      }
                      title={"AI Lesson Generator"}
                      content={
                        "With a few button clicks, generate full lesson slides and lesson plan on any topic with AI."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image
                          src={magicIcon}
                          alt="Magic sparkles"
                          loading="lazy"
                        />
                      }
                      title={"AI Quiz Generator"}
                      content={
                        "Generate a gamified quiz from a YouTube video, document, topic, or pasted text in no time."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image
                          src={documentIcon}
                          alt="Curriculum document"
                          loading="lazy"
                        />
                      }
                      title={"Curriculum Aligned"}
                      content={
                        "Paste in your curriculum expectations and standards to quickly create locally relevant learning material."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image
                          src={appleIcon}
                          alt="Teacher's apple"
                          loading="lazy"
                        />
                      }
                      title={"Teacher Created Resources"}
                      content={
                        " Pick from our library of ready-to-use materials made by teachers, for teachers."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image
                          src={graphIcon}
                          alt="Teacher analytics dashboard"
                          loading="lazy"
                        />
                      }
                      title={"Teacher Dashboard"}
                      content={
                        "Monitor student progress and see which students need additional support."
                      }
                    />

                    <HomePageCard
                      icon={
                        <Image
                          src={groupIcon}
                          alt="Teamwork and collaboration"
                          loading="lazy"
                        />
                      }
                      title={"Collaborative Learning"}
                      content={
                        "Promote teamwork and discussion with gamified review quizzes that foster peer instruction and collaboration."
                      }
                    />
                  </Grid>
                </View>
              </Flex>
            </Container>
          </View>

          <View
            as="section"
            padding={{ base: "small", large: "large" }}
            paddingTop="10vh"
            paddingBottom="5vh"
            className="hidden"
          >
            <Container>
              <Flex
                width="100%"
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
                gap={"xs"}
              >
                <Image
                  height={"128px"}
                  src={newsletterIcon}
                  alt="An email newsletter"
                  loading="lazy"
                />
                <Card>
                  <Flex padding={"large"} direction={"column"}>
                    <Text
                      fontWeight={"semibold"}
                      textAlign={"center"}
                      fontSize={{ base: "small", medium: "large" }}
                      paddingBottom={"small"}
                    >
                      Stay in the loop!
                      <br />
                      Join our newsletter.
                    </Text>
                    <Flex justifyContent={"center"}>
                      <Button
                        textAlign={"center"}
                        id={"raised-btn"}
                        size="large"
                        fontSize={{ base: "medium", medium: "larger" }}
                        gap={"small"}
                        backgroundColor={"white"}
                        color={"black"}
                        variation={"primary"}
                      >
                        <a
                          href="https://mailchi.mp/e9cae978810f/newsletter-signup"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: " var(--amplify-colors-neutral-90)",
                          }}
                        >
                          <FaBell /> Subscribe
                        </a>
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </Flex>
            </Container>
          </View>
        </Flex>

        <Footer />
      </View>
    </>
  );
}
