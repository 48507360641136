import React from "react";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";

import useModal from "../hooks/useModal";
import CreatePanel from "../features/quiz/CreatePanel";

import pencilIcon from "../assets/images/icons/pencil.png";

// import MailIcon from '../components/icons/MailIcon';

export default function SidebarCreateButton({
  navTitle,
  posthogType,
}: {
  navTitle: string;
  posthogType: string;
}) {
  const [CreateQuizModal, setShowCreateQuizModal] = useModal(
    {
      title: "Create",
      size: "lg",
      ReactComponent: () => (
        <CreatePanel onCreated={() => setShowCreateQuizModal(false)} />
      ),
      hasBackButton: true,
      hasExitButton: true,
    },
    []
  );

  return (
    <>
      <CreateQuizModal />

      <Button
        className="createButton"
        data-attr="create-btn"
        data-ph-capture-attribute-type={posthogType}
        padding={"0"}
        border={"none"}
        onClick={(e) => {
          e.stopPropagation();
          setShowCreateQuizModal(true);
        }}
      >
        <Flex
          direction={"column"}
          textAlign={"center"}
          gap={"0"}
          width={"68px"}
          height={{ base: "30px", small: "60px" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={pencilIcon}
            alt="A plus button"
            padding={"2px"}
            objectFit={"contain"}
            height={"xl"}
          />
          <Text
            className="navItemText"
            fontSize={{ base: "xxxs", small: "0.65rem" }}
            fontWeight={"semibold"}
            color={"neutral.80"}
          >
            {navTitle}
          </Text>
        </Flex>
      </Button>
    </>
  );
}
