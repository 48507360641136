import {
  Flex,
  Link,
  Text,
  Button,
  TextField,
  Divider,
  Heading,
} from "@aws-amplify/ui-react";
import React from "react";
import { RaisedButton } from "../../../components";
import { FaGoogle } from "react-icons/fa6";
import {
  signIn,
  SignInInput,
  SignInOutput,
  signInWithRedirect,
  SignInWithRedirectInput,
} from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type Props = {
  username: string;
  password: string;
  onSetUsername: (username: string) => void;
  onSetPassword: (password: string) => void;
  onNextStep: (nextStep: SignInOutput["nextStep"]) => void;
};

export function LogInForm({
  username,
  password,
  onSetUsername,
  onSetPassword,
  onNextStep,
}: Props) {
  const navigate = useNavigate();

  const {
    isError,
    error,
    isLoading,
    mutateAsync: login,
  } = useMutation(
    ["SignIn"],
    async (args: SignInInput) => {
      return await signIn({ ...args, options: { autoSignIn: true } });
    },
    {
      onSuccess: ({ nextStep }) => {
        onNextStep(nextStep);
      },
    }
  );
  const { mutateAsync: loginWithRedirect } = useMutation(
    ["SignInWithRedirect"],
    async (args: SignInWithRedirectInput) => {
      return await signInWithRedirect(args);
    },
    {
      onSuccess: () => {},
    }
  );

  return (
    <Flex direction={"column"} gap={"large"} paddingTop={"small"}>
      <Heading level={5} textAlign={"center"}>
        Log In
      </Heading>
      <Flex direction={"column"}>
        <Flex
          direction={"column"}
          gap={"small"}
          as="form"
          onSubmit={async (e) => {
            e.preventDefault();
            await login({ username, password });
          }}
        >
          <TextField
            isRequired
            label="Email"
            placeholder="Enter your email"
            type="email"
            value={username}
            onChange={(e) => {
              onSetUsername(e.target.value);
            }}
          ></TextField>
          <TextField
            isRequired
            label="Password"
            placeholder="Enter your password"
            type="password"
            value={password}
            onChange={(e) => {
              onSetPassword(e.target.value);
            }}
          ></TextField>

          <RaisedButton
            type="submit"
            isLoading={isLoading}
            loadingText="Logging In..."
          >
            Log In
          </RaisedButton>
          {isError ? (
            <Text variation="error">{(error as Error).message}</Text>
          ) : null}
          <Text fontSize={"small"}>
            Forgot password?{" "}
            <Link
              textDecoration={"underline"}
              onClick={() => {
                navigate("/auth/reset-password");
              }}
            >
              Reset your password
            </Link>
          </Text>
        </Flex>
        <Flex alignItems={"center"}>
          <Divider borderColor={"neutral.40"} />
          <Text color={"neutral.40"} fontSize={"xs"}>
            <b>or</b>
          </Text>
          <Divider borderColor={"neutral.40"} />
        </Flex>
        <Button
          gap={"small"}
          onClick={() => loginWithRedirect({ provider: "Google" })}
        >
          <FaGoogle /> Log In with Google
        </Button>
        <Text fontSize={"small"} textAlign={"center"}>
          Don't have an account?{" "}
          <Link
            textDecoration={"underline"}
            onClick={() => {
              navigate("/auth/signup");
            }}
          >
            Sign Up
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
}
