import React from "react";
import { Text, Flex } from "@aws-amplify/ui-react";

import { PageIndex, PageTitle } from "../util/SEO";

import DashboardLayout from "../layouts/DashboardLayout";
import RaisedButton from "../components/RaisedButton";
import useModal from "../hooks/useModal";
import { ReferAFriend } from "../features/referrals/components/ReferAFriend";

import Media from "react-media";

import UserDetails from "./Settings.UserDetails";
import ButtonCard from "./Settings.ButtonCard";
import SupportCard from "./Settings.SupportCard";
import SocialsCard from "./Settings.SocialsCard";
import PrivacyAndTermsCard from "./Settings.Privacy&TermsCard";
import { FaRobot } from "react-icons/fa";
import ReferralCard from "./Settings.ReferralCard";

export default function Settings() {
  PageTitle("Settings | Gibbly");
  PageIndex("noindex");

  const [ReferralModal, setReferralModal] = useModal(
    {
      size: "lg",
      title: "",
      ReactComponent: () => <ReferAFriend />,
      hasBackButton: false,
    },
    []
  );

  return (
    <>
      <ReferralModal />
      <DashboardLayout>
        <Flex
          padding={"medium"}
          paddingTop={"large"}
          gap={"zero"}
          direction={"column"}
        >
          <Flex justifyContent={"space-between"}>
            <Text id="pageTitle">Settings</Text>

            <Flex alignItems={"flex-end"}>
              <Media queries={{ small: { maxWidth: 480 } }}>
                {(matches) =>
                  matches.small ? (
                    <>
                      <RaisedButton
                        gap={"xs"}
                        fontSize={"smaller"}
                        aria-label="Earn Tokens"
                        variation={"primary"}
                        backgroundColor={"green.80"}
                        onClick={() => setReferralModal(true)}
                        data-attr="earn-tokens-btn"
                        data-ph-capture-attribute-type={"settings"}
                      >
                        <FaRobot />
                      </RaisedButton>
                    </>
                  ) : (
                    <>
                      <RaisedButton
                        gap={"xs"}
                        fontSize={"smaller"}
                        variation={"primary"}
                        backgroundColor={"green.80"}
                        onClick={() => setReferralModal(true)}
                        data-attr="earn-tokens-btn"
                        data-ph-capture-attribute-type={"settings"}
                      >
                        <FaRobot fontSize={"larger"} />
                        Earn Tokens
                      </RaisedButton>
                    </>
                  )
                }
              </Media>
            </Flex>
          </Flex>

          <Flex
            width={"100%"}
            grow={1}
            direction={{ base: "column", medium: "row" }}
            paddingTop={"medium"}
          >
            <Flex direction="column" gap="small" width={"100%"}>
              <UserDetails />
              <ReferralCard />

            </Flex>
            <Flex direction="column" gap="small" width={"100%"}>
              <PrivacyAndTermsCard />
              <SupportCard />
              <SocialsCard />
              <ButtonCard />
            </Flex>
          </Flex>
        </Flex>
      </DashboardLayout>
    </>
  );
}
