import React, { useMemo } from "react";
import { Grid, SliderField, Text, View } from "@aws-amplify/ui-react";

import ProductCard from "../components/ProductCard.new";

const prices = [
  { minSeats: 80, cad: 53.88, usd: 39.48, cad_disc: 47.99, usd_disc: 34.99 },
  { minSeats: 40, cad: 71.88, usd: 53.88, cad_disc: 52.99, usd_disc: 39.99 },
  { minSeats: 2, cad: 89.88, usd: 65.88, cad_disc: 58.99, usd_disc: 44.99 },
];

type VolumePricingCardProps = {
  interval: "month" | "year";
};

export default function VolumePricingCard({
  interval,
}: VolumePricingCardProps) {
  const [numSeats, setNumSeats] = React.useState<number>(20);

  const price = useMemo(() => {
    return prices.find((price) => price.minSeats <= numSeats) ?? prices[0];
  }, [numSeats]);

  return (
    <ProductCard
      title={(numSeats !== 80 ? numSeats : "80+") + " Premium Licences"}
      heading={
        <View paddingTop={"small"}>
          <Grid templateColumns={"1fr auto 1fr"} gap={"xs"}>
            {/* <Text
              textAlign={"right"}
              color={"purple.60"}
              alignSelf={"center"}
              fontFamily={"Poppins"}
              fontSize={"small"}
              textDecoration={"line-through"}
            >
              ${(price?.usd * (interval === "year" ? 1 : 0.083333)).toFixed(2)}
            </Text> */}
            <Text
              column={2}
              fontWeight={"bold"}
              fontFamily={"Poppins"}
              fontSize={"large"}
            >
              ${(price?.usd * (interval === "year" ? 1 : 0.083333)).toFixed(2)}{" "}
              USD
            </Text>
          </Grid>
          <Grid templateColumns={"1fr auto 1fr"} gap={"xs"}>
            {/* <Text
              textAlign={"right"}
              color={"purple.60"}
              alignSelf={"center"}
              fontFamily={"Poppins"}
              fontSize={"small"}
              textDecoration={"line-through"}
            >
              ${(price?.cad * (interval === "year" ? 1 : 0.083333)).toFixed(2)}
            </Text> */}
            <Text
              column={2}
              fontWeight={"semibold"}
              fontFamily={"Poppins"}
              fontSize={"larger"}
            >
              ${(price?.cad * (interval === "year" ? 1 : 0.083333)).toFixed(2)}{" "}
              CAD
            </Text>
          </Grid>
        </View>
        // <>
        //   <Text
        //     fontWeight={"bold"}
        //     fontFamily={"Poppins"}
        //     fontSize={"larger"}
        //     paddingTop={"medium"}
        //   >
        //     ${(price?.usd * (interval === "year" ? 12 : 1)).toFixed(2)} USD
        //   </Text>
        //   <Text
        //     fontWeight={"semibold"}
        //     fontFamily={"Poppins"}
        //     fontSize={"small"}
        //   >
        //     ${(price?.cad * (interval === "year" ? 12 : 1)).toFixed(2)} CAD
        //   </Text>
        // </>
      }
      subheading={
        <>
          <Text
            fontWeight={"semibold"}
            height={"60px"}
            fontSize={"small"}
            padding={"xs"}
            paddingBottom={"medium"}
            color={"purple.100"}
          >
            per licence, per {interval}, charged yearly
            <SliderField
              label="Per month per licence"
              labelHidden
              size="small"
              height={"60px"}
              padding={"0 large"}
              value={numSeats}
              onChange={(e) => setNumSeats(e)}
              min={2}
              max={80}
              filledTrackColor={"var(--amplify-colors-purple-60)"}
              emptyTrackColor={"var(--amplify-colors-purple-10)"}
              thumbColor="var(--amplify-colors-purple-90)"
            />
          </Text>
        </>
      }
      buttonText={"Request a Pilot"}
      highlightColor={"purple.60"}
      onClick={() => {
        window.open("https://outlook.office365.com/owa/calendar/Meetings@gibbly.co/bookings/", "_blank");
      }}
      features={[
        "Everything in Premium",
        "Professional development and training",
        "Dedicated support and check-ins",
        "Customization and branding requests",
      ]}
    ></ProductCard>
  );
}
