import { v4 } from "uuid";
import { getIdentityId } from "../../game/util";
import { uploadImageURL } from "../../s3/services/uploadImageURL";
import { getS3Url } from "../../../services/s3/getS3Url";

export async function uploadProtectedImageFromURL(
  src: string,
  onUpload?: (
    image: { key: string; identityId: string; alt: string },
    url: string
  ) => void
): Promise<
  | {
      image: { key: string; identityId: string; alt: string };
      url: string;
    }
  | undefined
> {
  try {
    // var imgExt = getUrlExtension(src);
    // const fileName = v4() + imgExt;
    const fileName = v4() + ".jpg";
    // const file = await urlToFile(src, fileName);
    // const res = await fetch(src);
    // const buf = await res.arrayBuffer();
    // const file = new File([buf], fileName, { type: `image/*` });
    // const response = await fetch(src);
    // const blob = await response.blob();
    // const file = new File([blob], fileName, {
    //   type: blob.type,
    // });
    // download file to local
    // const a = document.createElement("a");
    // a.href = URL.createObjectURL(file);
    // a.download = fileName;
    // a.click();

    // const result = await uploadS3Item({
    //   level: "protected",
    //   fileName,
    //   data: file,
    //   options: {
    //     contentType: `image/*`, // contentType is optional
    //   },
    // });
    const response = (await uploadImageURL(src, fileName)) as any;
    console.log("Response", response);
    const path = response.Key;
    const identityId = await getIdentityId();
    // const path = `protected/${identityId}/${fileName}`;
    // // get image from s3
    const { url } = await getS3Url({ version: 2, path: path });
    const image = {
      key: fileName,
      identityId,
      alt: `Image named ${fileName}`,
    };


    onUpload?.(image, url.href);
    return { image, url: url.href };
  } catch (error) {
    console.error("Error uploading image from URL", error);
    return;
  }
}

// const getUrlExtension = (url: string | any) => {
//   return "." + url.split(/[#?]/)[0].split(".").pop().trim();
// };

// async function urlToFile(
//   url: string,
//   filename: string,
//   mimeType = "image/jpeg"
// ) {
//   // Fetch the image data
//   const res = await fetch(url);

//   // Check the response status
//   if (!res.ok) {
//     throw new Error(`Failed to fetch image: ${res.status} - ${res.statusText}`);
//   }

//   // Determine the MIME type based on the response headers
//   const contentType = res.headers.get("Content-Type");
//   if (contentType) {
//     mimeType = contentType;
//   }

//   // Convert the response to an ArrayBuffer
//   const buf = await res.arrayBuffer();

//   // Create a new File object with the detected MIME type
//   return new File([buf], filename, { type: mimeType });
// }
