import React, { useState } from "react";
import NewQuizForm from "./components/NewQuizForm";
import { Button, Card, Flex, Image, Text } from "@aws-amplify/ui-react";
import useEffectOnce from "../../hooks/useEffectOnce";
import { subscribe, unsubscribe } from "../../util/events";
import { createLesson } from "../lesson/services/createLesson";
import { useNavigate } from "react-router-dom";
import { getIdentityId } from "../game/util";
import { toast } from "react-toastify";
import useUserQuery from "../../hooks/useUserQuery";
import GibblyLoader from "../../components/GibblyLoader";
import GenerateQuizForm from "../generate/components/GenerateQuiz";

import newDocumentIcon from "../../assets/images/icons/new-document.png";
import magicIcon from "../../assets/images/icons/magic.png";
import magicWandIcon from "../../assets/images/icons/magic-wand.png";

enum CreateState {
  MENU,
  BLANK,
  GENERATE,
}

export default function CreatePanel({ onCreated }: { onCreated: () => void }) {
  const [state, setState] = useState<CreateState>(CreateState.MENU);
  const [creatingLesson, setCreatingLesson] = useState(false);

  const navigate = useNavigate();

  const { tokenBalanceString, isLoading } = useUserQuery();

  useEffectOnce(() => {
    subscribe("onModalBack", back);

    return () => {
      unsubscribe("onModalBack", back);
    };
  });

  function back() {
    setState(CreateState.MENU);
  }

  async function handleCreateLesson() {
    setCreatingLesson(true);
    try {
      const lesson = await createLesson();

      if (lesson) {
        const userId = await getIdentityId();
        navigate(`/lesson/${userId}/${lesson.id}`);
      }
    } catch (err) {
      toast.error("Error creating lesson. Please try again.");
    }
    setCreatingLesson(false);
  }

  switch (state) {
    case CreateState.MENU:
      return (
        <Flex direction={"column"}>
          <Flex wrap={"wrap"}>
            <Flex flex={1}>
              <Card width={"100%"} backgroundColor={"background.tertiary"}>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} fontSize={"larger"}>
                    🧠 Quiz
                  </Text>
                  <Flex justifyContent={"center"}>
                    <Button
                      data-attr="blank-quiz"
                      direction={"column"}
                      id={"raised-btn"}
                      height={{ base: "100px", medium: "200px" }}
                      width={{ base: "100px", medium: "200px" }}
                      backgroundColor={"#1a90ff"}
                      color={"white"}
                      gap={"medium"}
                      onClick={() => setState(CreateState.BLANK)}
                    >
                      <Image
                        height={{ base: "xl", medium: "xxxl" }}
                        src={newDocumentIcon}
                        alt="Blank sheet of paper"
                        objectFit={"contain"}
                      />

                      <Text
                        color={"white"}
                        textAlign={"center"}
                        fontSize={{ base: "smaller", medium: "medium" }}
                      >
                        Manually
                      </Text>
                    </Button>
                    <Button
                      data-attr="ai-gen-quiz"
                      textAlign={"center"}
                      direction={"column"}
                      height={{ base: "100px", medium: "200px" }}
                      width={{ base: "100px", medium: "200px" }}
                      id={"raised-btn"}
                      gap={"medium"}
                      backgroundColor={"#fd7e14"}
                      color={"white"}
                      onClick={() => setState(CreateState.GENERATE)}
                    >
                      <Image
                        height={{ base: "xl", medium: "xxxl" }}
                        src={magicIcon}
                        alt="Sparkles"
                        objectFit={"contain"}
                      />

                      <Text
                        color={"white"}
                        textAlign={"center"}
                        fontSize={{ base: "smaller", medium: "medium" }}
                      >
                        AI Generated
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
            <Flex flex={1}>
              <Card width={"100%"} backgroundColor={"background.tertiary"}>
                <Flex direction={"column"}>
                  <Text fontWeight={"bold"} fontSize={"larger"}>
                    📃 Lesson
                  </Text>
                  <Flex justifyContent={"center"}>
                    <Button
                      direction={"column"}
                      id={"raised-btn"}
                      height={{ base: "100px", medium: "200px" }}
                      width={{ base: "100px", medium: "200px" }}
                      backgroundColor={"#9100ff"}
                      color={"white"}
                      gap={"medium"}
                      onClick={() => handleCreateLesson()}
                      isLoading={creatingLesson}
                      data-attr="ai-assisted-lesson"
                    >
                      <Image
                        height={{ base: "xl", medium: "xxxl" }}
                        src={magicWandIcon}
                        alt="Magic wand"
                        objectFit={"contain"}
                      />

                      <Text
                        color={"white"}
                        textAlign={"center"}
                        fontSize={{ base: "smaller", medium: "medium" }}
                      >
                        AI Generated
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          </Flex>
          <Text>
            <b>Tokens Left:</b>{" "}
            {isLoading ? <GibblyLoader /> : tokenBalanceString}
          </Text>
        </Flex>
      );
    case CreateState.BLANK:
      return <NewQuizForm onSuccess={onCreated} />;
    case CreateState.GENERATE:
      return <GenerateQuizForm onSuccess={onCreated} />;
  }
}
