import * as React from "react";
import {
  Heading,
  Flex,
  Button,
  TextField,
  Text,
  Placeholder,
} from "@aws-amplify/ui-react";
import RaisedCard from "../components/RaisedCard";
import { ReferAFriend } from "../features/referrals/components/ReferAFriend";
import useModal from "../hooks/useModal";
import { FaHandHoldingHeart } from "react-icons/fa6";
import { RaisedButton } from "../components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useUserContext } from "../context";
import { createRefferalWithCode } from "../features/referrals/utils/createReferralWithCode";
import { toast } from "react-toastify";
import getReferredReferral from "../features/referrals/utils/getReferredReferral";
import getUser from "../util/User/getUser";
import { useCanvasConffeti } from "../hooks/useCanvasConfetti";

export interface IPrivacyAndTermsCardProps {}

export default function ReferralCard(props: IPrivacyAndTermsCardProps) {
  const {} = props;
  const { user } = useUserContext();
  const queryClient = useQueryClient();
  const [refCode, setRefCode] = React.useState("");

  // const { status, isError } = useQuery(
  //   ["refferal-code-exists", refCode],
  //   async () => {
  //     return await getReferrerId(refCode);
  //   },
  //   {
  //     enabled: refCode.length === 6,
  //   }
  // );
  const {
    data: referrer,
    isLoading: loadingReferrer,
  } = useQuery(
    ["referrer", user?.creatorId],
    async () => {
      const referral = await getReferredReferral(user?.creatorId as string);
      const referrer = getUser(referral[0].referrerId);
      return referrer;
    },
    {
      enabled: !!user,
      retry: false,
    }
  );
  const { fireCannon } = useCanvasConffeti();

  const [ReferredModal, setReferredModal] = useModal(
    {
      title: "Referral Reward Unlocked! 🎉",
      ReactComponent: () => (
        <Flex direction={"column"} alignItems={"center"}>
          <Text>
            You and your friend will receive <b>10,000 bonus tokens</b> each as
            a thank you from Team Gibbly! 🧡
          </Text>
        </Flex>
      ),
      hasBackButton: false,
    },
    []
  );

  const { mutateAsync: redeemCode, isLoading: redeemingCode } = useMutation({
    mutationKey: ["redeem code", refCode],
    mutationFn: async () => {
      if (!user) throw new Error("User not found");
      await createRefferalWithCode(refCode, user?.creatorId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["referrer"]);
      setTimeout(() => {
        queryClient.invalidateQueries(["user"]);
      }, 2000);

      setReferredModal(true);
      fireCannon("left", false);
      fireCannon("right", false);
    },
    onError: () => {
      toast.error("Invalid referral code");
    },
  });
  const [ReferralModal, setReferralModal] = useModal(
    {
      size: "lg",
      title: "",
      ReactComponent: () => <ReferAFriend />,
      hasBackButton: false,
    },
    []
  );

  return (
    <>
      <ReferralModal />
      <ReferredModal />
      <RaisedCard>
        <Heading level={5} id="sectionTitle">
          Referrals
        </Heading>

        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          wrap={"wrap"}
          gap={"xs"}
          alignContent={"center"}
          direction={{base:"column", small:"row"}}
        >
          <Flex gap={"xxs"} alignItems={"start"} flex={1}>
            {/* <Heading fontSize={"small"} alignSelf={"start"}>
                Referral:
              </Heading> */}
            {loadingReferrer ? (
              <Placeholder size="large" maxWidth={"250px"} />
            ) : referrer ? (
              <Text fontSize={"smaller"}>
                Yay! You were referred by <b>{referrer.username}</b> 🎉
              </Text>
            ) : (
              <>
                <TextField
                  label="Referral code"
                  size="small"
                  variation="quiet"
                  width={"160px"}
                  labelHidden
                  placeholder="Enter referral code"
                  value={refCode}
                  onChange={(e) => {
                    if (e.target.value.length > 10) return;
                    setRefCode(e.target.value.toUpperCase());
                  }}
                />
                <RaisedButton
                  size="small"
                  fontSize={"smaller"}
                  isLoading={redeemingCode || loadingReferrer}
                  onClick={() => redeemCode()}
                >
                  Redeem
                </RaisedButton>
              </>
            )}
          </Flex>

          {/* //User enters in Referral Code, if it's valid, save it, don't let them edit the field again.  */}
          {/* Afterwards change to say "Yay! You were referred by {username} 🎉 " */}
          <Button
            variation="link"
            gap={"xs"}
            fontSize={"smaller"}
            onClick={() => setReferralModal(true)}
            data-attr="earn-tokens-btn"
            data-ph-capture-attribute-type={"settings-referral-section"}
          >
            <FaHandHoldingHeart fontSize={"larger"} />
            Refer Friends
          </Button>
        </Flex>
      </RaisedCard>
    </>
  );
}
