import React from "react";
import {
  Button,
  Flex,
  Image,
  Link,
  useAuthenticator,
  MenuItem,
  Divider,
  Menu,
  ResponsiveStyle,
  MenuButton,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import logo from "/logoWide.png";
import SimpleLogo from "../assets/logoSimple.png";
import Media from "react-media";
import DiscoverIcon from "../components/icons/DiscoverIcon";
import SettingsIcon from "../components/icons/SettingsIcons";
import CreatePanel from "../features/quiz/CreatePanel";
import useModal from "../hooks/useModal";
import { Property } from "csstype";
import {
  FaBookOpen,
  FaGraduationCap,
  FaCrown,
  FaPlusCircle,
  FaGripLines,
} from "react-icons/fa";
import useEffectOnce from "../hooks/useEffectOnce";
// import MailIcon from '../components/icons/MailIcon';

export default function TopNavbar({
  useAuthentication,
  area,
}: {
  useAuthentication: boolean;
  area?: ResponsiveStyle<Property.GridArea>;
}) {
  const navigate = useNavigate();
  const { route } = useAuthenticator((context) => [context.route]);

  useEffectOnce(() => {

  });

  const [CreateQuizModal, setShowCreateQuizModal] = useModal(
    {
      title: "Create",
      ReactComponent: () => (
        <CreatePanel onCreated={() => setShowCreateQuizModal(false)} />
      ),
      hasBackButton: true,
    },
    []
  );

  if (useAuthentication) {
    return (
      <Flex
        area={area}
        height={"56px"}
        justifyContent={"space-between"}
        backgroundColor={"white"}
        boxShadow={"0px -2px rgb(0 0 0 / 10%) inset"}
        padding={"small"}
        style={{
          backgroundImage:
            "linear-gradient(to top left, var(--amplify-colors-teal-100) ,var(--amplify-colors-teal-60))",
        }}
      >
        <CreateQuizModal />
        <Flex justifyContent={"start"}>
          <Button
            as={Link}
            variation={"link"}
            size="small"
            onClick={() => navigate("/dashboard/library/")}
          >
            <Media queries={{ medium: { maxWidth: 768 } }}>
              {(matches) =>
                matches.medium ? (
                  <>
                    <Image
                      objectFit={"contain"}
                      height={"45px"}
                      width={"45px"}
                      src={SimpleLogo}
                      alt="Gibbly Logo"
                      paddingRight={"zero"}
                      marginRight={"zero"}
                    />
                  </>
                ) : (
                  <>
                    <Image
                      objectFit={"contain"}
                      height={"45px"}
                      width={"135px"}
                      src={logo}
                      alt="Gibbly Logo"
                      padding={"zero"}
                      margin={"zero"}
                    />
                  </>
                )
              }
            </Media>
          </Button>
        </Flex>
        <Flex
          justifyContent={"space-around"}
          alignItems={"center"}
          marginRight={"30px"}
        >
          {route === "authenticated" ? (
            <Media queries={{ small: { maxWidth: 992 } }}>
              {(matches) =>
                matches.small ? (
                  <>
                    <Flex>
                      <Menu
                        trigger={
                          <MenuButton
                            color={"neutral.90"}
                            size="small"
                            backgroundColor={"neutral.10"}
                            variation="primary"
                          >
                            <FaGripLines />
                          </MenuButton>
                        }
                      >
                        <MenuItem
                          gap={"xxs"}
                          onClick={() => setShowCreateQuizModal(true)}
                          data-attr="create-btn"
                        >
                          <FaPlusCircle />
                          Create
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          gap={"xxs"}
                          onClick={() => {

                            navigate("/dashboard/discover/");
                          }}
                        >
                          <DiscoverIcon />
                          Discover
                        </MenuItem>
                        <MenuItem
                          gap={"xxs"}
                          onClick={() => navigate("/dashboard/library/")}
                        >
                          <FaBookOpen />
                          My Library
                        </MenuItem>
                        <MenuItem
                          gap={"xxs"}
                          onClick={() => navigate("/classes")}
                        >
                          <FaGraduationCap /> My Classes
                        </MenuItem>

                        <Divider />

                        <MenuItem
                          gap={"xxs"}
                          onClick={() => navigate("/settings")}
                        >
                          <SettingsIcon /> Settings
                        </MenuItem>

                        <MenuItem
                          gap={"xxs"}
                          onClick={() => navigate("/dashboard/upgrade")}
                        >
                          <FaCrown color="gold" /> Upgrade
                        </MenuItem>

                        {/* <MenuItem gap={'xxs'} onClick={signOut}><QuitIcon />Logout</MenuItem> */}
                      </Menu>
                    </Flex>
                  </>
                ) : (
                  <></>
                )
              }
            </Media>
          ) : null
          // <>{navigate("/auth")}</>
          }
        </Flex>
      </Flex>
    );
  } else
    return (
      <Flex
        height={"56px"}
        justifyContent={"space-between"}
        backgroundColor={"white"}
        boxShadow={"0px -2px rgb(0 0 0 / 10%) inset"}
      >
        <CreateQuizModal />

        <Flex justifyContent={"start"} padding={0}>
          <Button variation={"link"} padding={0} onClick={() => navigate("/")}>
            <Image
              objectFit={"cover"}
              height={"45px"}
              width={"135px"}
              src={logo}
              alt="Gibbly Logo"
            />
          </Button>
        </Flex>
      </Flex>
    );
}
