/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getQuestion = /* GraphQL */ `query GetQuestion($id: ID!) {
  getQuestion(id: $id) {
    id
    image {
      key
      identityId
      alt
      __typename
    }
    audio {
      key
      identityId
      alt
      __typename
    }
    readAloudText
    text
    type
    solution
    answers
    correctIndices
    Quizzes {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuestionQueryVariables,
  APITypes.GetQuestionQuery
>;
export const listQuestions = /* GraphQL */ `query ListQuestions(
  $filter: ModelQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      image {
        key
        identityId
        alt
        __typename
      }
      audio {
        key
        identityId
        alt
        __typename
      }
      readAloudText
      text
      type
      solution
      answers
      correctIndices
      Quizzes {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuestionsQueryVariables,
  APITypes.ListQuestionsQuery
>;
export const getQuiz = /* GraphQL */ `query GetQuiz($id: ID!) {
  getQuiz(id: $id) {
    id
    creator
    image {
      key
      identityId
      alt
      __typename
    }
    allowReadTo
    title
    description
    tags
    Questions {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    lang
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetQuizQueryVariables, APITypes.GetQuizQuery>;
export const listQuizzes = /* GraphQL */ `query ListQuizzes(
  $filter: ModelQuizFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuizzesQueryVariables,
  APITypes.ListQuizzesQuery
>;
export const quizzesByCreator = /* GraphQL */ `query QuizzesByCreator(
  $creator: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQuizFilterInput
  $limit: Int
  $nextToken: String
) {
  quizzesByCreator(
    creator: $creator
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuizzesByCreatorQueryVariables,
  APITypes.QuizzesByCreatorQuery
>;
export const quizzesByAllowReadToAndCreatedAt = /* GraphQL */ `query QuizzesByAllowReadToAndCreatedAt(
  $allowReadTo: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQuizFilterInput
  $limit: Int
  $nextToken: String
) {
  quizzesByAllowReadToAndCreatedAt(
    allowReadTo: $allowReadTo
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuizzesByAllowReadToAndCreatedAtQueryVariables,
  APITypes.QuizzesByAllowReadToAndCreatedAtQuery
>;
export const searchQuizzes = /* GraphQL */ `query SearchQuizzes(
  $filter: SearchableQuizFilterInput
  $sort: [SearchableQuizSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableQuizAggregationInput]
) {
  searchQuizzes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchQuizzesQueryVariables,
  APITypes.SearchQuizzesQuery
>;
export const getQuizQuestion = /* GraphQL */ `query GetQuizQuestion($id: ID!) {
  getQuizQuestion(id: $id) {
    id
    quizId
    questionId
    quiz {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    question {
      id
      image {
        key
        identityId
        alt
        __typename
      }
      audio {
        key
        identityId
        alt
        __typename
      }
      readAloudText
      text
      type
      solution
      answers
      correctIndices
      Quizzes {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    sortIndex
    updatedAt
    createdAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuizQuestionQueryVariables,
  APITypes.GetQuizQuestionQuery
>;
export const listQuizQuestions = /* GraphQL */ `query ListQuizQuestions(
  $filter: ModelQuizQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuizQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      quizId
      questionId
      quiz {
        id
        creator
        image {
          key
          identityId
          alt
          __typename
        }
        allowReadTo
        title
        description
        tags
        Questions {
          nextToken
          __typename
        }
        lang
        updatedAt
        createdAt
        __typename
      }
      question {
        id
        image {
          key
          identityId
          alt
          __typename
        }
        audio {
          key
          identityId
          alt
          __typename
        }
        readAloudText
        text
        type
        solution
        answers
        correctIndices
        Quizzes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      sortIndex
      updatedAt
      createdAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuizQuestionsQueryVariables,
  APITypes.ListQuizQuestionsQuery
>;
export const quizQuestionsByQuizIdAndSortIndex = /* GraphQL */ `query QuizQuestionsByQuizIdAndSortIndex(
  $quizId: ID!
  $sortIndex: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQuizQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  quizQuestionsByQuizIdAndSortIndex(
    quizId: $quizId
    sortIndex: $sortIndex
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quizId
      questionId
      quiz {
        id
        creator
        image {
          key
          identityId
          alt
          __typename
        }
        allowReadTo
        title
        description
        tags
        Questions {
          nextToken
          __typename
        }
        lang
        updatedAt
        createdAt
        __typename
      }
      question {
        id
        image {
          key
          identityId
          alt
          __typename
        }
        audio {
          key
          identityId
          alt
          __typename
        }
        readAloudText
        text
        type
        solution
        answers
        correctIndices
        Quizzes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      sortIndex
      updatedAt
      createdAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuizQuestionsByQuizIdAndSortIndexQueryVariables,
  APITypes.QuizQuestionsByQuizIdAndSortIndexQuery
>;
export const quizQuestionsByQuestionIdAndUpdatedAt = /* GraphQL */ `query QuizQuestionsByQuestionIdAndUpdatedAt(
  $questionId: ID!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQuizQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  quizQuestionsByQuestionIdAndUpdatedAt(
    questionId: $questionId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quizId
      questionId
      quiz {
        id
        creator
        image {
          key
          identityId
          alt
          __typename
        }
        allowReadTo
        title
        description
        tags
        Questions {
          nextToken
          __typename
        }
        lang
        updatedAt
        createdAt
        __typename
      }
      question {
        id
        image {
          key
          identityId
          alt
          __typename
        }
        audio {
          key
          identityId
          alt
          __typename
        }
        readAloudText
        text
        type
        solution
        answers
        correctIndices
        Quizzes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      sortIndex
      updatedAt
      createdAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuizQuestionsByQuestionIdAndUpdatedAtQueryVariables,
  APITypes.QuizQuestionsByQuestionIdAndUpdatedAtQuery
>;
