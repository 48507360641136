import RestAPI from "../../../util/RestAPI";
import { DeleteAccountRequest } from "../types/DeleteAccountRequest";

export async function getDeleteAccountRequest(id: string | undefined) {
  try {
    if (!id) throw new Error("ID is required");
    return (await (
      await RestAPI.get(`/delete-account-requests/object/${id}`)
    ).json()) as DeleteAccountRequest;
  } catch (error) {
    throw error;
  }
}
