import { Image } from "@aws-amplify/ui-react";
import React from "react";
import goldCrown from "../../../assets/images/icons/crown.png";
import greyCrown from "../../../assets/images/icons/crown-grey.png";
import { useUserContext } from "../../../context/UserContextProvider";

export function PremiumFeatureBadge() {
  const { user } = useUserContext();

  return (
    <Image
      alt="premium-crown"
      src={user?.customerTier !== "Premium" ? goldCrown : greyCrown}
      height={"15px"}
    ></Image>
  );
}
