import {
  Flex,
  View,
  Image,
  ImageProps,
  Placeholder,
} from "@aws-amplify/ui-react";
import * as React from "react";
import ImageGalleryButton from "../features/generate/components/ImageGalleryButton";
import RaisedButton from "./RaisedButton";
import DeleteIcon from "./icons/DeleteIcon";
import SparkleIcon from "./icons/SparkleIcon";
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { S3ObjectProtected } from "../API";
import { useAutoPickImageMutation } from "../hooks/useAutoPickImageMutation";
import { AutoPickedImage } from "../services/autoPickImages";
import { getS3Url } from "../services/s3/getS3Url";

// const defaultImageUrl =
//   "https://media-bucket105725-dev.s3.us-east-1.amazonaws.com/public/default-quiz-img.png?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEE4aCXVzLWVhc3QtMSJHMEUCIH8Pga7izjNfr4muEblvcJe91QPptmIaxI5UUt0oJ0QaAiEA6jgTsw%2F6pVO%2Fyxan6eivL%2F4ryrx4V3q026LdQQ44G0kq%2FwIIFxABGgwwNTQ1MTM4NDQ2MTUiDAuhjx78lF%2FWQTr6nircAnBpBe%2BMb9QOAB7huuVJYSZOKgp2gIJaQKfBL5unn3TuAQx2VzfOxmCV1x6wgFc9xNvYzqZF%2BsC9NeVr42N%2FPMsSknr9za34CDiX6DA%2FumSMpAFagnFwkL7XhE6tHN8SvzGq%2BedpODFhGLY5%2FHZ11JticV0foNHgNY9LPG0DW1kQhk%2Bsj71MYpSnRXVEw2oD%2B681iqaozL8MjE18kJvJfN8FuABDvmiblB%2ByqONEa5oO2aQkTX1AdVbgy1dVGryU%2BK1ojjHS%2FpVZsoH1CJtgPwxRV27k3ooGVXPjssbEq1%2BrlmKh4LghJIpzADkoVIt8JX7dFV3J%2Bbe72WXOSy1tPlsBAGVuwvsJ8PMnAuXeDMD3FJnth3hHOs33P5WIEa4sU7ijujjvidu4Rc8DwIo0tiTg8v76rR6HiXjv9p%2BuRFOJaMtxNfyrWM%2FP2%2BLC0jcXHdumu3T%2F4p5o0TamGjDe1K%2B0BjqzArU9BB5f%2F7qFQmaQU6UL96f6plb%2B633pb3yE32Xhr3PfyobUVaVIDFMxXQV2xc%2Fx9hzsnPOGaZsL%2FGKrqfQ%2BNHjOiSkcEc8nSroREjd1uX6hTLIIDsYhgbewpX%2BgCGjuddLJ2TYWQQAY%2BXNCiOy%2FGTjbjDhjsEByvZrRnbCwB2VeFq10379kL2gYuHtm7R3PncFBkUfevrh%2Fbw1TQcgCuf%2B0jSt3WoUThHRNPoNVCTu%2BGn270mBZf%2FPuwbkFaP5PLYotLgPb7AXNKsV7vxDH7F3smQ7zq6h%2F1GAJOl1pMnfPnqhXM2AQIwSMYb%2FEMf7mQE%2F1PieX%2F3xBlqAqCwVtX52FD5rm9BZ2b0pb%2B5nqiOVf35%2B4t6aBkUZJK4zfB4SFtrn%2BkdJ6dO7F2awo3vuiAgKZgCg%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240708T134946Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAQZMKHIGDYA3TNZM6%2F20240708%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=5f366fe7931d622d14d2c548a0b0900a4fdf37848272bef845f3bb9910d658b5";

import defaultCoverImage from "../assets/images/misc/default-cover-img.png";

export type ImageComponentHandle = {
  autoPickImage: UseMutateAsyncFunction<
    AutoPickedImage | null,
    Error,
    void,
    unknown
  >;
};

export type IImageComponentProps = Omit<ImageProps, "src"> & {
  image: S3ObjectProtected | null | undefined;
  aspectRatio?: string;
  canEdit?: boolean;
  autoPickPrompt: string;
  updateImage?: ({
    image,
    url,
    dimensions,
  }: {
    image: S3ObjectProtected;
    url: string;
    dimensions: any;
  }) => Promise<void>;
};

export const ImageComponent = React.forwardRef<
  ImageComponentHandle,
  IImageComponentProps
>((props, ref) => {
  const {
    width,
    height,
    image,
    canEdit,
    updateImage,
    aspectRatio,
    autoPickPrompt,
    ...rest
  } = props;

  React.useImperativeHandle(ref, () => ({
    autoPickImage: () =>
      autoPickImage({
        prompt: autoPickPrompt,
        onUpload: (image, url, dimensions) => {
          updateImageMutate({ image, url, dimensions });
        },
      }),
  }));

  const { data: url, isLoading } = useQuery({
    queryKey: ["s3-image", image?.key, image?.identityId],
    queryFn: async () => {
      if (!image || image?.key === "" || image?.identityId === "")
        return defaultCoverImage;
      try {
        const url = await getS3Url({
          version: 1,
          key: image.key,
          identityId: image?.identityId,
          level: "protected",
        }).then((res) => res.url.href);

        return url;
      } catch {
        return defaultCoverImage;
      }
    },
    refetchInterval: 10 * 60 * 1000,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateImageMutate } = useMutation({
    mutationFn: updateImage,
    // When mutate is called:
    onMutate: async ({ url, image: newImage }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)

      const newKey = newImage?.key;
      const newIdentityId = newImage?.identityId;
      const key = image?.key;
      const identityId = image?.identityId;
      await queryClient.cancelQueries({
        queryKey: ["s3-image", key, identityId],
      });

      // Snapshot the previous value
      const previousUrl: string | undefined = queryClient.getQueryData([
        "s3-image",
        key,
        identityId,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(["s3-image", newKey, newIdentityId], url);

      // Return a context object with the snapshotted value
      return { previousUrl };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, _, context) => {
      console.error(err);
      queryClient.setQueryData(
        ["s3-image", image?.key, image?.identityId],
        context?.previousUrl
      );
    },
    // Always refetch after error or success:
    onSettled: (_, error, { image }) => {
      if (error) console.error(error);
      queryClient.invalidateQueries({
        queryKey: ["s3-image", image.key, image.identityId],
      });
    },
  });

  const { autoPickImage, isLoading: isAutoPicking } =
    useAutoPickImageMutation();

  return (
    <View
      width={width}
      height={height}
      style={{ position: "relative", aspectRatio }}
    >
      {isLoading || isAutoPicking ? (
        <Placeholder width={"100%"} height={"100%"} />
      ) : (
        <Image
          {...rest}
          src={url}
          alt={image?.alt ?? ""}
          onError={(e) => {
            if (typeof e === "string") {
              console.error(e);
            } else {
              e.currentTarget.src = defaultCoverImage;
            }
          }}
          objectFit="cover"
          // objectPosition="50% 50%"
          // backgroundColor="initial"
          width={"100%"}
          height={"100%"}
          opacity="100%"
          borderRadius={"small"}
          style={{ aspectRatio }}
        />
      )}

      {canEdit && (
        <>
          <Flex position={"absolute"} top={"small"} right={"small"}>
            <View>
              <RaisedButton
                size="small"
                backgroundColor={"white"}
                color={"black"}
                onClick={() => {
                  updateImageMutate({
                    image: {
                      key: "",
                      identityId: image?.identityId,
                    } as S3ObjectProtected,
                    url: defaultCoverImage,
                    dimensions: {},
                  });
                }}
              >
                <DeleteIcon />
              </RaisedButton>
            </View>
          </Flex>
          <Flex
            gap={"xs"}
            position={"absolute"}
            bottom={"small"}
            right={"small"}
          >
            <View>
              <RaisedButton
                size="small"
                backgroundColor={"#9100ff"}
                onClick={() =>
                  autoPickImage({
                    prompt: autoPickPrompt,
                    onUpload: (image, url, dimensions) => {
                      updateImageMutate({ image, url, dimensions });
                    },
                  })
                }
              >
                <SparkleIcon />
              </RaisedButton>
            </View>
            <View>
              <ImageGalleryButton
                size="small"
                fileName={image?.key}
                onSelectImage={(image, url, dimensions) => {
                  console.log("ON SELECT IMAGE: ", image, url, dimensions);
                  updateImageMutate({ image, url, dimensions });
                }}
                text={autoPickPrompt}
              />
            </View>
          </Flex>
        </>
      )}
    </View>
  );
});
