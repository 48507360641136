import { getCurrentUser } from "aws-amplify/auth";
import { invokeLambdaFunction } from "../awsFacade";
import { refreshUserSession } from "./refreshUserSession";

export async function addUserToPublicGroup() {
  const cognitoUser = await getCurrentUser();

  const cognitoUsername = cognitoUser.username;

  const {
    body: { action },
  } = await invokeLambdaFunction("AddUserToGroup", {
    username: cognitoUsername,
  });
  console.log({ action });
  if (action === "added") {
    await refreshUserSession();
    window.location.reload();
  }
}
