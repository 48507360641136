import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getSynthesis, readAndHighlightText } from "../services";
import { useTranslationContext, useVolumeContext } from "../../../context";
import { useQueryClient } from "@tanstack/react-query";
import { RaisedButton } from "../../../components";
import { FaStop } from "react-icons/fa6";
import TTSIcon from "../../../components/icons/TTSIcon";
import { Button, Flex } from "@aws-amplify/ui-react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Speed } from "../types";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

type ScreenReaderContextType = {
  speaking: boolean;
  elementRef: React.MutableRefObject<HTMLDivElement | null>;
  ScreenReadButton: () => React.JSX.Element;
  audio: HTMLAudioElement | undefined;
  stop: () => void;
};

type WordFragment = {
  word: string;
  fragments: string[];
};

type SpeechWord = {
  word: string;
  charIndex: number;
  markedHTML: string;
};
// type ElementInfo = {
//   tag: string;
//   text: string;
//   children: ElementInfo[];
// };
type ScreenReaderContextProviderProps = {
  children: React.ReactNode;
};

const ScreenReaderContext = React.createContext<ScreenReaderContextType>({
  speaking: false,
  elementRef: { current: null },
  ScreenReadButton: () => <></>,
  audio: undefined,
  stop: () => {},
});

export function useScreenReaderContext() {
  return useContext(ScreenReaderContext);
}

export function ScreenReaderContextProvider({
  children,
}: ScreenReaderContextProviderProps) {
  // const [speaking, setSpeaking] = useState(false);
  // const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [speaking, setSpeaking] = useState(false);

  const { lang } = useTranslationContext();
  const { setMultiplier } = useVolumeContext();
  const [speed, setSpeed] = useLocalStorage<{ value: Speed }>(
    "screen-read-speed",
    { value: "medium" }
  );

  const isStoppedRef = useRef(false);

  const queryClient = useQueryClient();

  const audioRef = useRef<HTMLAudioElement>();

  const location = useLocation();

  const SpeakWords = useCallback(
    async (element: Element) => {
      const ogHTML = element.innerHTML;
      const text = element.textContent ?? "";
      if (!text) {
        toast.warn("No text to read.");
        return;
      }
      const { markings } = await getSynthesis({
        queryClient,
        text,
        lang,
        speed: speed.value,
      });

      const words = markings.map((marking) => marking.value);

      if (!words) return;
      const tagTexts = listTagSeperatedText(element.outerHTML);
      const wordFragments = getWordFragments(tagTexts, words);
      const speechWords = createSpeechGuide(wordFragments, element);

      await readAndHighlightText({
        queryClient,
        text,
        lang,
        speed: speed.value,
        onPause: () => {
          isStoppedRef.current = true;
        },
        onEnd: () => {
          isStoppedRef.current = false;
        },
        onStart: (audio) => {
          audioRef.current = audio;
          isStoppedRef.current = false;
        },
        onWord: (marking) => {
          const start = marking.start - 30;
          // marking.start - `<speak><prosody rate="${speed}">`.length;

          const word = speechWords.findLast((w) => w.charIndex <= start);
          if (!word) return;
          // if (!element.isConnected) audioRef.current?.pause();
          element.innerHTML = word.markedHTML;
        },
      });
      element.innerHTML = ogHTML;
    },
    [lang, queryClient, speed]
  );

  const handleSpeak = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!elementRef.current) return;

    const targetElements = getTextBlockElements(elementRef.current);

    isStoppedRef.current = false;
    setSpeaking(true);
    setMultiplier(0.15);
    for (const targetElement of targetElements) {
      if (isStoppedRef.current) break;
      // const targetElementClone = targetElement.cloneNode(true) as Element;
      // const words = targetElement.textContent?.split(" ");
      // if (!words) return;
      // const tagTexts = listTagSeperatedText(targetElement.outerHTML);
      // const wordFragments = getWordFragments(tagTexts, words);
      // const speechWords = createSpeechGuide(wordFragments, targetElement);

      await SpeakWords(targetElement);
    }
    setMultiplier(1);
    setSpeaking(false);
  };

  const stopSpeaking = useCallback(() => {
    if (audioRef.current && speaking) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [speaking]);

  useEffect(() => {
    return () => {
      stopSpeaking();
    };
  }, [location, stopSpeaking]);

  return (
    <ScreenReaderContext.Provider
      value={{
        speaking: false,
        elementRef: elementRef,
        audio: audioRef.current,
        stop: stopSpeaking,
        ScreenReadButton: () => (
          <Flex direction={"column"} alignItems={"center"} gap={"xxxs"}>
            <RaisedButton
              placement="bottom"
              tooltip={"Screen Read"}
              borderRadius={"50px"}
              backgroundColor={"rgba(255,255,255,0.6)"}
              variation="link"
              padding={"xs xs small xs"}
              justifyContent={"center"}
              alignItems={"center"}
              height={{ base: "35px", medium: "50px" }}
              data-attr={"screen-reader-btn"}
              // data-ph-capture-attribute-audio-key={
              //   "read-aloud"
              // }
              style={{
                aspectRatio: "1",
              }}
              // isDisabled={speaking}
              // isLoading={speaking}
              onClick={async (e) => {
                e.stopPropagation();
                if (speaking && audioRef.current) {
                  stopSpeaking();
                } else {
                  await handleSpeak(e);
                }
              }}
            >
              {speaking ? (
                <FaStop height={"100%"} />
              ) : (
                <TTSIcon height={"100%"} />
              )}
            </RaisedButton>
            <Button
              borderRadius={"50px"}
              backgroundColor={"rgba(255,255,255, 0.6)"}
              variation="link"
              padding={"xs"}
              justifyContent={"center"}
              alignItems={"center"}
              fontSize={"xxs"}
              fontWeight={"normal"}
              height={"10px"}
              style={{
                aspectRatio: "1",
              }}
              onClick={async (e) => {
                e.stopPropagation();

                setSpeed({
                  value:
                    speed.value === "medium"
                      ? "x-slow"
                      : speed.value === "x-slow"
                      ? "x-fast"
                      : "medium",
                });
              }}
            >
              {speed.value === "medium"
                ? "Normal"
                : speed.value === "x-fast"
                ? "Fast"
                : "Slow"}
            </Button>
          </Flex>
        ),
      }}
    >
      {children}
    </ScreenReaderContext.Provider>
  );
}

function markFragments(
  html: string,
  fragments: string[],
  startIndex: number
): [string, number] {
  let isTag = false;
  let fi = 0;
  const markIndexes: { start: number; fragment: string }[] = [];
  for (let i = startIndex; i < html.length; i++) {
    if (html[i] === "<") {
      isTag = true;
    } else if (html[i] === ">") {
      isTag = false;
    }
    if (isTag) continue;

    if (html.slice(i, i + fragments[fi].length) === fragments[fi]) {
      markIndexes.push({ start: i, fragment: fragments[fi] });
      fi++;
      if (fi === fragments.length) break;
    }
  }
  let markedHTML = html;
  markIndexes.reverse().forEach(({ start, fragment }) => {
    markedHTML = `${markedHTML.slice(
      0,
      start
    )}<mark>${fragment}</mark>${markedHTML.slice(fragment.length + start)}`;
  });

  const lastMark = markIndexes[markIndexes.length - 1];
  const newStartIndex = lastMark.start + lastMark.fragment.length;

  return [markedHTML, newStartIndex];
}

function listTagSeperatedText(html: string): string[] {
  const regex = /(?<=>)([^<>]+?)(?=<)/g; // match text between html tags
  // const textContext = html.replace(/<[^>]*>/g, " ").replace(/\s+/g, " ");
  const childTagTextContent = html.match(regex);

  // const seperatedText

  return childTagTextContent ?? [];
  // else {
  //   return [html];
  // }
}

function getWordFragments(tagTexts: string[], words: string[]): WordFragment[] {
  const wordFragments: WordFragment[] = [];
  let fi = 0;

  const fragments = tagTexts.flatMap((text) => text.split(" "));

  words?.forEach((word) => {
    let combined = "";
    for (let i = fi; i < fragments.length; i++) {
      combined += fragments[i];
      if (
        combined === word.replace(" ", "") ||
        combined.includes(word.replace(" ", ""))
      ) {
        wordFragments.push({
          word,
          fragments: fragments.slice(fi, i + 1).filter((f) => f !== ""),
        });
        fi = i + 1;
        break;
      }
    }
  });

  return wordFragments.filter((wf) => wf.word !== "");
}

function createSpeechGuide(wordFragments: WordFragment[], element: Element) {
  let readIndex = 0;
  let charIndex = 0;
  const speechWords: SpeechWord[] = [];

  wordFragments.forEach((wordFragment) => {
    const [markedHTML, newReadIndex] = markFragments(
      element.innerHTML,
      wordFragment.fragments,
      readIndex
    );
    readIndex = newReadIndex;
    speechWords.push({
      word: wordFragment.word,
      markedHTML,
      charIndex,
    });
    // Create a TextEncoder instance
    const encoder = new TextEncoder();

    // Encode the string into a Uint8Array
    const encoded = encoder.encode(wordFragment.word);

    // Get the byte length
    const byteLength = encoded.length;

    charIndex += byteLength + 1;
  });
  return speechWords;
}

function getTextBlockElements(element: Element): Element[] {
  // Define the selectors for the elements you want to distinguish
  const selectors = [
    "p",
    "li",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "blockquote",
  ];

  // Create an array to store the matching elements
  const textBlockElements: Element[] = [];

  // Iterate over each selector and find matching elements within the provided element
  selectors.forEach((selector) => {
    const elements = element.querySelectorAll(selector);
    elements.forEach((el) => textBlockElements.push(el));
  });

  return textBlockElements;
}
