import RestAPI from "../../../util/RestAPI";
import { Referral } from "../types/referralTypes";

export default async function getReferredReferral(creatorId: string) {
  try {
    return await RestAPI.get(`/referrals/referred/${creatorId}`).then(
      async (res) => {
        const referrals = (await res.json()) as any as Referral[];
        console.log("referrals", referrals);
        return referrals;
      }
    );
  } catch (err) {
    console.error(`Something went wrong`, err);
    throw err;
  }
}
