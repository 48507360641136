import { Grid, ScrollView } from "@aws-amplify/ui-react";
import React, { Suspense } from "react";
import TopNavbar from "./TopNavbar";
import { CenteredLoader } from "../components";

export default function BasicLayout({
  children,
  useAuthentication = true,
}: {
  children?: React.ReactNode;
  useAuthentication?: boolean;
}) {
  return (
    <>
      <Grid
        height={"100vh"}
        minHeight={"100vh"}
        columnGap="0"
        rowGap="0"
        templateColumns={"1fr"}
        templateRows={"auto 1fr"}
      >
        <TopNavbar useAuthentication={useAuthentication} />

        <Suspense fallback={<CenteredLoader />}>
          <ScrollView backgroundColor={"#f5f5f5"} position={"relative"}>
            {children}
          </ScrollView>
        </Suspense>
      </Grid>
    </>
  );
}
