import RestAPI from "../../../util/RestAPI";
import getCreatorId from "../../../util/GetCreatorId";
import { deleteUser } from "aws-amplify/auth";

export default async function deleteAccount() {
  const creatorId = await getCreatorId();
  if (!creatorId) throw new Error("Creator ID is required");

  await RestAPI.del(`/users/object/${creatorId}`);

  try {
    await deleteUser();
  } catch (err) {
    throw err;
  }
}
