import React, { FormEvent, useMemo, useState } from "react";

import {
  Button,
  TextField,
  Flex,
  SelectField,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";

import { PageIndex, PageTitle } from "../../../util/SEO";
import useEffectOnce from "../../../hooks/useEffectOnce";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import { GenerationInput } from "./GenerateQuiz";
import { extractVideoId } from "../util/extractVideoId";
import { toast } from "react-toastify";
import InfoIcon from "../../../components/icons/InfoIcon";
import { placeholderYouTubeUrls } from "../../../data/PlaceholderYouTubeUrls";
import LanguageSelect from "../../../components/LanguageSelect";
import { useTranslationContext } from "../../../context/TranslationContextProvider";
import { LanguageOption } from "../../translations";

export default function YoutubeForm({
  loading,
  onGenerate,
}: {
  onGenerate: (input: GenerationInput) => void;
  loading: boolean;
}) {
  PageTitle("Gibbly | Generate Quiz");
  PageIndex("noindex");

  const [topicPlaceholder, setTopicPlaceholder] = useState("");

  const { prefLang } = useTranslationContext();

  const [formInput, setFormInput] = useState({
    url: "",
    numberOfQuestions: "20",
    gradeLevel: "Easy",
    lang: prefLang,
    instructions: "",
  });

  const videoId = useMemo(
    () => extractVideoId(formInput.url) ?? "",
    [formInput.url]
  );

  useEffectOnce(() => {
    const randomIndex = Math.floor(
      Math.random() * placeholderYouTubeUrls.length
    );
    const randomPlaceholder = placeholderYouTubeUrls[randomIndex];
    setTopicPlaceholder(randomPlaceholder);
  });

  const handleTopicSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (extractVideoId(formInput.url) === null) {
      toast.error("Invalid YouTube URL");
      return;
    }
    onGenerate({
      prompt: {
        topic: videoId,
        numberOfQuestions: formInput.numberOfQuestions,
        gradeLevel: formInput.gradeLevel,
        lang: formInput.lang,
        instructions: formInput.instructions,
      },
      type: "yt-mixed",
    });
  };

  return (
    <Flex
      as="form"
      direction={"column"}
      padding={"0"}
      onSubmit={handleTopicSubmit}
    >
      <TextField
        fontSize={"small"}
        size="small"
        label={
          <Flex gap={"xs"} alignItems={"center"}>
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              {" "}
              YouTube URL{" "}
            </Text>
            <InfoIcon message="FYI the longer the video, the more tokens it uses" />
          </Flex>
        }
        isRequired
        placeholder={topicPlaceholder}
        // hasError={topicHasError}
        errorMessage={"Must provide a topic"}
        value={formInput.url}
        onChange={(e) =>
          setFormInput((prev) => ({ ...prev, url: e.target.value }))
        }
      />
      <TextAreaField
        fontSize={"small"}
        size="small"
        label={
          <Text fontWeight={"semibold"} color={"neutral.90"}>
            Instructions
          </Text>
        }
        // placeholder={topicPlaceholder}
        // hasError={topicHasError}
        errorMessage={"Must provide a topic"}
        value={formInput.instructions}
        onChange={(e) =>
          setFormInput((prev) => ({ ...prev, instructions: e.target.value }))
        }
      />
      <Flex wrap={"wrap"}>
        <SelectField
          flex={1}
          fontSize={"small"}
          size="small"
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Grade Level
            </Text>
          }
          isRequired
          value={formInput.gradeLevel}
          onChange={(e) =>
            setFormInput((prev) => ({ ...prev, gradeLevel: e.target.value }))
          }
        >
          <option value={"Easy"}>Easy</option>
          <option value={"Medium"}>Medium</option>
          <option value={"Hard"}>Hard</option>
          <option value={"Grade 1"}>Grade 1</option>
          <option value={"Grade 2"}>Grade 2</option>
          <option value={"Grade 3"}>Grade 3</option>
          <option value={"Grade 4"}>Grade 4</option>
          <option value={"Grade 5"}>Grade 5</option>
          <option value={"Grade 6"}>Grade 6</option>
          <option value={"Grade 7"}>Grade 7</option>
          <option value={"Grade 8"}>Grade 8</option>
          <option value={"Grade 9"}>Grade 9</option>
          <option value={"Grade 10"}>Grade 10</option>
          <option value={"Grade 11"}>Grade 11</option>
          <option value={"Grade 12"}>Grade 12</option>
          <option value={"College/University"}>College/University</option>
        </SelectField>
        <LanguageSelect
          fontSize={"small"}
          size="small"
          flex={1}
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Language
            </Text>
          }
          isRequired
          value={formInput.lang}
          onChange={(lang) =>
            setFormInput((prev) => ({ ...prev, lang: lang as LanguageOption }))
          }
        ></LanguageSelect>
        <SelectField
          fontSize={"small"}
          size="small"
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              # of Questions
            </Text>
          }
          isRequired
          value={formInput.numberOfQuestions}
          onChange={(e) =>
            setFormInput((prev) => ({
              ...prev,
              numberOfQuestions: e.target.value,
            }))
          }
        >
          // from 1 to 20
          {[...Array(20).keys()].map((i) => (
            <option key={i} value={(i + 1).toString()}>
              {i + 1}
            </option>
          ))}
        </SelectField>
      </Flex>

      <Button
        id="raised-btn"
        backgroundColor={"#fd7e14"}
        variation="primary"
        gap={"small"}
        type="submit"
        color={"white"}
        alignSelf={"center"}
        isLoading={loading}
        loadingText={"Generating..."}
        data-attr="generate-new-quiz"
        data-ph-capture-attribute-type={"youtube"}
        data-ph-capture-attribute-prompt={formInput.url}
        data-ph-capture-attribute-grade-level={formInput.gradeLevel}
        data-ph-capture-attribute-language={formInput.lang}
        data-ph-capture-attribute-instructions={formInput.instructions}
        data-ph-capture-attribute-number-of-questions={
          formInput.numberOfQuestions
        }
      >
        <SparkleIcon />
        Generate
      </Button>
    </Flex>
  );
}
