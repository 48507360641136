import { useEffect, useState } from "react";

export function useImagePreload(images: string[]) {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [failedImages, setFailedImages] = useState<string[]>([]);

  useEffect(() => {
    const promises = images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(src);
        img.onerror = () => reject(src);
      });
    });

    Promise.all(promises)
      .then((images) => {
        setLoadedImages(images as string[]);
      })
      .catch((images) => {
        setFailedImages(images as string[]);
      });
  }, [images]);

  return { loadedImages, failedImages };
}
