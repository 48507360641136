import {
  getUrl,
  GetUrlWithPathInput,
  GetUrlWithPathOutput,
} from "aws-amplify/storage";
import { getCurrentUser } from "aws-amplify/auth";
import RestAPI from "../../util/RestAPI";

type GetS3UrlOptions =
  | ({
      version: 1;
      key: string;
    } & (
      | {
          level: "public";
        }
      | {
          level: "protected";
          identityId?: string;
        }
    ))
  | ({
      version: 2;
    } & GetUrlWithPathInput);

/***
 * Get the S3 URL for the given key and level
 * You must define a version number at minimun. Version 1 complies with previous amplify storage api implementation, version 2 is the new one.
 * @param {GetS3UrlOptions} options
 * @returns {Promise<GetUrlWithPathOutput>}
 */
export async function getS3Url(
  options: GetS3UrlOptions
): Promise<GetUrlWithPathOutput> {
  const authenticated = await getCurrentUser()
    .then(() => true)
    .catch(() => false);

  if (authenticated === false) {
    const path =
      options.version === 1
        ? options.level === "protected"
          ? `protected/${options.identityId}/${options.key}`
          : `public/${options.key}`
        : options.path;
    const resp = await RestAPI.get(`/s3/${path}`);
    const { url } = (await resp.json()) as { url: string };
    return { url: new URL(url), expiresAt: new Date() };
  } else {
    if (options.version === 1) {
      const { key, level } = options;
      if (level === "protected") {
        const { identityId } = options;
        return await getUrl({
          path: identityId
            ? `protected/${identityId}/${key}`
            : ({ identityId }) => `protected/${identityId}/${key}`,
          options: {
            validateObjectExistence: true,
          },
        });
      } else {
        return await getUrl({ path: `public/${key}` });
      }
    } else {
      const { version, ...getUrlOptions } = options;

      return await getUrl(getUrlOptions);
    }
  }
}
