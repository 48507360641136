import React from "react";
import {
  Flex,
  Divider,
  Text,
  ResponsiveStyle,
  Image,
} from "@aws-amplify/ui-react";

import { Property } from "csstype";
import SidebarNavLink from "./SidebarNavLink";
import { LessonContext } from "../features/lesson/components/LessonContextProvider";
import {
  MenuContext,
  MenuState,
} from "../features/lesson/components/MenuContextProvider";
import Media from "react-media";

import previousIcon from "../assets/images/icons/previous.png";
import documentIcon from "../assets/images/icons/document.png";
import pptIcon from "../assets/images/icons/ppt.png";
import paintCanIcon from "../assets/images/icons/paint-can.png";

export default function CreateSidebar({
  area,
  hidden,
}: {
  area?: ResponsiveStyle<Property.GridArea>;
  hidden?: boolean;
}) {
  const { isOwner } = React.useContext(LessonContext);
  const { setMenuState } = React.useContext(MenuContext);

  return (
    <>
      <Media queries={{ small: { maxWidth: 480 } }}>
        {(matches) =>
          matches.small ? (
            <>
              <Flex flex={1} height={"100dvh"} minHeight={"100vh"}>
                <Flex
                  direction={"column"}
                  width={"100%"}
                  style={{
                    zIndex: "25",
                    backgroundImage:
                      "linear-gradient(to top left, var(--amplify-colors-teal-100) ,var(--amplify-colors-teal-60))",
                  }}
                  boxShadow={"0px 2px 0px 0px rgb(0 0 0 / 10%) inset"}
                  padding={"small"}
                  position={"absolute"}
                  bottom={"0"}
                  paddingLeft={"xxs"}
                  paddingRight={"xxs"}
                  hidden={hidden}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Flex direction={"row"} gap={"0.2em"}>
                    <SidebarNavLink
                      navIcon={
                        <Image
                          src={previousIcon}
                          alt="Back to Dashboard"
                          padding={"xxxs"}
                          objectFit={"contain"}
                          height={"large"}
                        />
                      }
                      navTitle={"Dashboard"}
                      to="/dashboard/library"
                      color={"neutral.80"}
                    />

                    <Divider />

                    {isOwner ? (
                      <>
                        {" "}
                        <SidebarNavLink
                          navIcon={
                            <Image
                              src={documentIcon}
                              alt="Lesson plan document"
                              padding={"xxxs"}
                              objectFit={"contain"}
                              height={"large"}
                            />
                          }
                          navTitle={"Lesson Plan"}
                          onClick={(e) => {
                            e.preventDefault();
                            setMenuState(MenuState.OVERVIEW);
                          }}
                          to={"#overview"}
                        />
                        <SidebarNavLink
                          navIcon={
                            <Image
                              src={pptIcon}
                              alt="PowerPoint slides"
                              padding={"xxxs"}
                              objectFit={"contain"}
                              height={"large"}
                            />
                          }
                          navTitle={"Slides"}
                          onClick={(e) => {
                            e.preventDefault();
                            setMenuState(MenuState.ADD);
                          }}
                          data-attr="slides-btn"
                          data-ph-capture-attribute-type={"owner-navbar"}
                          to={"#slides"}
                        />
                        {/* <View as={SidebarNavLink} navIcon={<FaUpload />} navTitle={"Media"} onClick={(e) => {e.preventDefault(); setMenuState(MenuState.MEDIA)}} /> */}
                        {/* <View as={SidebarNavLink} navIcon={<FaFont />} navTitle={"Text"} onClick={(e) => {e.preventDefault(); setMenuState(MenuState.TEXT)}} /> */}
                        <SidebarNavLink
                          to="#background"
                          navIcon={
                            <Image
                              src={paintCanIcon}
                              alt="Paint can"
                              padding={"xxxs"}
                              objectFit={"contain"}
                              height={"large"}
                            />
                          }
                          navTitle={"Background"}
                          onClick={(e) => {
                            e.preventDefault();
                            setMenuState(MenuState.BACKGROUND);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <SidebarNavLink
                          to="#overview"
                          navIcon={
                            <Image
                              src={documentIcon}
                              alt="Lesson plan document"
                              padding={"xxxs"}
                              objectFit={"contain"}
                              height={"large"}
                            />
                          }
                          navTitle={"Lesson Plan"}
                          onClick={(e) => {
                            e.preventDefault();
                            setMenuState(MenuState.OVERVIEW);
                          }}
                        />
                        <SidebarNavLink
                          to="#slides"
                          navIcon={
                            <Image
                              src={pptIcon}
                              alt="PowerPoint slides"
                              padding={"xxxs"}
                              objectFit={"contain"}
                              height={"large"}
                            />
                          }
                          navTitle={"Slides"}
                          onClick={(e) => {
                            e.preventDefault();
                            setMenuState(MenuState.PREVIEW);
                          }}
                        />
                      </>
                    )}
                  </Flex>

                  {/* <Text textAlign={"center"} fontSize={"xxxs"} color="neutral.80">
                  © 2024 Gibbly Inc
                </Text> */}
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              <Flex
                direction={"column"}
                boxShadow={"-2px 0px rgb(0 0 0 / 10%) inset"}
                padding={"small"}
                backgroundColor={"white"}
                paddingLeft={"xxs"}
                paddingRight={"xxs"}
                area={area}
                hidden={hidden}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Flex direction={"column"} gap={"0.2em"}>
                  <SidebarNavLink
                    navIcon={
                      <Image
                        src={previousIcon}
                        alt="Back to Dashboard"
                        padding={"2px"}
                        objectFit={"contain"}
                        height={"large"}
                      />
                    }
                    navTitle={"Dashboard"}
                    to="/dashboard/library"
                  />

                  <Divider />

                  {isOwner ? (
                    <>
                      {" "}
                      <SidebarNavLink
                        to="#overview"
                        navIcon={
                          <Image
                            src={documentIcon}
                            alt="Lesson plan document"
                            padding={"2px"}
                            objectFit={"contain"}
                            height={"large"}
                          />
                        }
                        navTitle={"Lesson Plan"}
                        onClick={(e) => {
                          e.preventDefault();
                          setMenuState(MenuState.OVERVIEW);
                        }}
                      />
                      <SidebarNavLink
                        to="#slides"
                        navIcon={
                          <Image
                            src={pptIcon}
                            alt="PowerPoint slides"
                            padding={"2px"}
                            objectFit={"contain"}
                            height={"large"}
                          />
                        }
                        navTitle={"Slides"}
                        onClick={(e) => {
                          e.preventDefault();
                          setMenuState(MenuState.ADD);
                        }}
                        data-attr="slides-btn"
                        data-ph-capture-attribute-type={"owner-navbar"}
                      />
                      {/* <View 
                       navIcon={<UploadIcon />} navTitle={"Media"} onClick={(e) => {e.preventDefault(); setMenuState(MenuState.MEDIA)}} /> */}
                      {/* <View 
                       navIcon={<FaFont />} navTitle={"Text"} onClick={(e) => {e.preventDefault(); setMenuState(MenuState.TEXT)}} /> */}
                      <SidebarNavLink
                        to="#background"
                        navIcon={
                          <Image
                            src={paintCanIcon}
                            alt="Paint can"
                            padding={"2px"}
                            objectFit={"contain"}
                            height={"large"}
                          />
                        }
                        navTitle={"Background"}
                        onClick={(e) => {
                          e.preventDefault();
                          setMenuState(MenuState.BACKGROUND);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <SidebarNavLink
                        to="#overview"
                        navIcon={
                          <Image
                            src={documentIcon}
                            alt="Lesson plan document"
                            padding={"2px"}
                            objectFit={"contain"}
                            height={"large"}
                          />
                        }
                        navTitle={"Lesson Plan"}
                        onClick={(e) => {
                          e.preventDefault();
                          setMenuState(MenuState.OVERVIEW);
                        }}
                      />
                      <SidebarNavLink
                        to="#slides"
                        navIcon={
                          <Image
                            src={pptIcon}
                            alt="PowerPoint slides"
                            padding={"2px"}
                            objectFit={"contain"}
                            height={"large"}
                          />
                        }
                        navTitle={"Slides"}
                        onClick={(e) => {
                          e.preventDefault();
                          setMenuState(MenuState.PREVIEW);
                        }}
                      />
                    </>
                  )}
                </Flex>

                <Text textAlign={"center"} fontSize={"xxxs"} color="neutral.80">
                  © 2024 Gibbly Inc
                </Text>
              </Flex>
            </>
          )
        }
      </Media>
    </>
  );
}
