import { QueryClient } from "@tanstack/react-query";
import { SynthesizedMarking, Speed, Synthesis } from "../types";
import { synthesizeSpeechAudio, synthesizeSpeechMarks } from "..";
import { LanguageOption } from "../../translations";

type Args = {
  queryClient?: QueryClient;
  text: string;
  lang: LanguageOption;
  speed: Speed;
};

export async function getSynthesis({ queryClient, text, lang, speed }: Args) {
  let cached: Synthesis | undefined;
  if (queryClient) {
    cached = queryClient.getQueryData([
      "speech-synthesis",
      text,
      lang,
      speed,
    ]) as Synthesis;
  }

  // let audio: HTMLAudioElement;
  let markings: SynthesizedMarking[];
  if (cached) {
    return cached;
  } else {
    const marksStream = await synthesizeSpeechMarks(text, lang, speed);
    const audioStream = await synthesizeSpeechAudio(text, lang, speed);

    const string = await marksStream.transformToString();
    console.log("string", string);
    try {
      markings = string
        .trim()
        .split("\n")
        .map((mark) => JSON.parse(mark));
    } catch (e) {
      console.error(e);
      throw new Error("Failed to synthesize speech.");
    }

    const byteArray = await audioStream.transformToByteArray();

    const url = URL.createObjectURL(new Blob([byteArray]));
    // audio = new Audio(url);

    if (queryClient)
      queryClient.setQueryData(["speech-synthesis", text, lang, speed], {
        url,
        markings,
      });

    return { url, markings } as Synthesis;
  }
}
